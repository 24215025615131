import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import DataTable from './DataTable';
import localStorageService from 'services/LocalStorageService';
import StoreService from 'services/StoreService';
import ApiService from 'services/apiService';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const initialValues = {
  storeCode: '',
  storeName: '',
  storePhone: '',
  storeAddr: '',
  storeAddrLat: '',
  storeAddrLng: '',
  storeIntro: '',
  storeService: '',
  storePlace: '',
  storeReserve: '',
  storeImage: '',
  storeBigImage: '',
  storeWebsite: '',
  storeLineoa: '',
  storeFacebook: '',
  storeProdName: '',
};

export default function Table() {
  const classes = useStyles();
  const [storeData, setstoreData] = React.useState([]);

  React.useEffect(() => getStoreData(), []);
  const getStoreData = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    StoreService.getByStoreCode(storeCode)
      .then(response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/store/info/${storeCode}`,
            requestMethod: 'GET',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          // console.log('API response product date', response.data)
          setstoreData(response.data.storeList);
        } else {
          console.log('store', 'store/data response:' + JSON.stringify(response.data));
        }
      })
      .catch(function(error) {
        console.log('error', error);
      });
  };
  return (
    <PageContainer heading={<IntlMessages id="sidebar.store.info" />}>
      <Box className={classes.section}>
        <DataTable storeData={storeData} getStoreData={getStoreData} initialValues={initialValues} />
      </Box>
    </PageContainer>
  );
}
