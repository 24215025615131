import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { useFormik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { createStore, updateStore, retrieveStore } from '../../../../redux/actions/StoreAction';
import qs from 'qs';
import storeService from '../../../../services/StoreService';
import { CREATE_STORE } from '@jumbo/constants/ActionTypes';
import commonService from '../../../../services/CommonService';

// import DataTable from './DataTable';
// import FormDialog from './FormDialog';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];

const fields = [
  { name: 'id', elementName: 'input', type: 'hidden', placeholder: '' },
  {
    name: 'storeCode',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入店家代碼',
  },
  {
    name: 'storeName',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入店家名稱',
  },
  {
    name: 'storePhone',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入營業電話',
  },
  {
    name: 'storeAddr',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入營業地址',
  },
  {
    name: 'storeAddrLat',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入地址緯度',
  },
  {
    name: 'storeAddrLng',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入地址經度',
  },
];

// { fields.map((f,i) => {return <Field {...f} key={i} value={this.props.values[f.name]} name={f.name} /> })}

const validate = values => {
  const errors = {};
  if (!values.storeCode) {
    errors.storeCode = '必填';
  } else if (values.storeCode.length > 50) {
    errors.firstName = '超過最大字數長度(50)';
  }
  if (!values.storeName) {
    errors.storeName = '必填';
  } else if (values.storeName.length > 50) {
    errors.storeName = '超過最大字數長度(50)';
  }

  return errors;
};

export default function Table() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const qstr = location.search;
  const qstrobj = qs.parse(qstr, { ignoreQueryPrefix: true });
  console.log('qstrobj', qstrobj);
  const storeId = qstrobj.i;
  let isEditMode = storeId ? true : false;

  useEffect(() => {
    // dispatch(retrieveStore(storeId));
    if (storeId) {
      storeService
        .get(storeId)
        .then(res => {
          console.log('res', res);
          if (res.status === 200) {
            console.log('isEditMode', isEditMode);
            console.log('res.data', res.data);
            formik.setFieldValue('id', res.data.id);
            formik.setFieldValue('storeCode', res.data.storeCode);
            formik.setFieldValue('storeName', res.data.storeName);
            formik.setFieldValue('storeEnabled', res.data.storeEnabled === true ? '1' : '0');
            // formik.setFieldValue('sendEmailResetPwd', '0');
          }
        })
        .catch(() => {
          // setSubmitting(false);
          // alertService.error(error);
        });
    }
  }, [dispatch]);

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      id: '',
      storeCode: '',
      storeName: '',
      storeEnabled: '1',
      // sendEmailResetPwd: '1',
    },
    validate,
    onSubmit: values => {
      const storeData = {
        storeCode: values.storeCode,
        storeName: values.storeName,
        storeEnabled: values.storeEnabled,
        // sendEmailResetPwd: values.sendEmailResetPwd,
      };
      commonService.debugMsg(JSON.stringify(storeData, null, 2));
      if (!values.id) {
        dispatch(createStore(storeData));
      } else {
        dispatch(updateStore(values.id, storeData));
      }

      history.push(`/store/list`);
      // dispatch(createStore(values.storeCode, values.storeName));
    },
    onReset: (values, { resetForm }) => resetForm(),
  });

  return (
    <PageContainer heading={<IntlMessages id="pages.store.create" />} breadcrumbs={breadcrumbs}>
      <Box
        className={classes.section}
        sx={{
          backgroundColor: '#FFF',
          margin: 'auto',
          padding: '10px',
          boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
          borderRadius: '5px',
        }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-control"
                  id="id"
                  name="id"
                  type="hidden"
                  placeholder=""
                  data-sb-validations="required"
                  // onChange={this.props.onChange}
                  // onBlur={this.props.onBlur}
                  // value={this.props.value}
                  // onChange={e => this.props.onChange(e)}
                />
              </div>
            </div>
            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-control"
                  id="storeCode"
                  name="storeCode"
                  type="text"
                  placeholder="請輸入店家代碼(必填)"
                  onChange={formik.handleChange}
                  value={formik.values.storeCode}
                />
                <div className="help-block text-danger">
                  {formik.errors.storeCode ? <div>{formik.errors.storeCode}</div> : null}
                </div>
              </div>
            </div>
            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-control"
                  id="storeName"
                  name="storeName"
                  type="text"
                  placeholder="請輸入店家名稱(必填)"
                  onChange={formik.handleChange}
                  value={formik.values.storeName}
                />
                <div className="help-block text-danger">
                  {formik.errors.storeName ? <div>{formik.errors.storeName}</div> : null}
                </div>
              </div>
            </div>

            {/* <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <div role="group" aria-labelledby="my-radio-group">
                  <label>
                    <Field type="radio" name="storeEnabled" value="1" onChange={formik.handleChange} />
                    One
                  </label>
                  <label>
                    <Field type="radio" name="storeEnabled" value="0" onChange={formik.handleChange} />
                    Two
                  </label>
                </div>
              </div>
            </div> */}

            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-check-input"
                  id="enableStore"
                  name="storeEnabled"
                  type="radio"
                  placeholder=""
                  onChange={formik.handleChange}
                  // value={formik.values.storeEnabled}
                  value="1"
                  checked={formik.values.storeEnabled === '1'}
                />
                <label className="mr-3 ml-1" htmlFor="adminEnabled">
                  啟用
                </label>
                <input
                  className="form-check-input"
                  id="disableStore"
                  name="storeEnabled"
                  type="radio"
                  placeholder=""
                  onChange={formik.handleChange}
                  value="0"
                  checked={formik.values.storeEnabled === '0'}
                />
                <label className="ml-1" htmlFor="adminEnabled">
                  不啟用
                </label>
              </div>
            </div>

            {/* <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-check-input"
                  id="sendEmailResetPwd"
                  name="sendEmailResetPwd"
                  type="radio"
                  placeholder=""
                  onChange={formik.handleChange}
                  value="1"
                  checked={formik.values.sendEmailResetPwd === '1'}
                  disabled={isEditMode ? '' : 'disabled'}
                />
                <label className="mr-3 ml-1" htmlFor="sendEmailResetPwd">
                  系統自動產生(重設)密碼並發送EMAIL
                </label>
                <input
                  className="form-check-input"
                  id="notResetPwd"
                  name="sendEmailResetPwd"
                  type="radio"
                  placeholder=""
                  onChange={formik.handleChange}
                  value="0"
                  checked={formik.values.sendEmailResetPwd === '0'}
                  disabled={isEditMode ? '' : 'disabled'}
                />
                <label className="ml-1" htmlFor="notResetPwd">
                  不產生(重設)密碼
                </label>
              </div>
            </div> */}

            <div className="row align-items-stretch mb-2 mt-3">
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary mr-2">
                  {isEditMode ? <IntlMessages id="button.store.update" /> : <IntlMessages id="button.store.create" />}
                </button>
                <button type="button" className="btn btn-outline-secondary" onClick={history.goBack}>
                  {<IntlMessages id="button.store.cancel" />}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Box>
    </PageContainer>
  );
}
