import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import FormDialog from './FormDialog';
import newNameCode from 'services/nameCodeGenerator';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

let currentDate = new Date();
const initialValues = {
  cpnCode: '',
  cpnName: '',
  cpnIntro: '',
  cpnDesc: '',
  cpnImage: '',
  cpnBigImage: [''],
  couponId: null,
  cpnStartTime: currentDate.toString(),
  cpnStopTime: currentDate.setDate(currentDate.getDate() + 7),
  cpnStatus: false,
  cpnTarget: 'STORE',
  cpnQty: 0,
  cpnQtyGiven: 0,
  cpnGiveRepeat: false,
};
export default function Table() {
  const classes = useStyles();
  const [coupon, setCoupon] = React.useState();
  React.useEffect(() => {
    getCouponList();
  }, []);
  const getCouponList = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.Coupon.list(storeCode)
      .then(response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/coupon/list/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          console.log('response', response.data);
          setCoupon(response.data.couponList);
          newName('CPN', response.data.couponList.reverse());
        } else {
          console.log('coupon', 'coupon/list response:' + JSON.stringify(response.data));
        }
      })
      .catch(err => console.log('err', err));
  };
  const newName = (prefix, dataList) => {
    if (dataList != undefined) {
      let nameCodeDB = dataList.map(dataInstance => {
        return dataInstance.cpnCode;
      });
      initialValues.cpnCode = newNameCode(prefix, nameCodeDB);
    }
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.coupon.list" />}>
      <Box className={classes.section}>
        <DataTable coupon={coupon} getCouponList={getCouponList} />
      </Box>
      <Box className={classes.section}>
        <FormDialog coupon={coupon} initialValues={initialValues} getCouponList={getCouponList} />
      </Box>
    </PageContainer>
  );
}
