import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '../../../../@jumbo/constants/TableIcons';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];

export default function Table() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    columns: [
      { title: 'No.', render: rowData => rowData.tableData.id + 1 },
      { title: '訊息編號', field: 'messageCode' },
      // { title: '訊息類別', field: 'msgType' },
      { title: '訊息標題', field: 'subject' },
      { title: '訊息內容', field: 'messageBody' },
      { title: '訊息完成時間', field: 'createdAt', type: 'datetime' },
    ],
    // data: [
    //   {
    //     msgId: '202202011830-001',
    //     msgType: 'NEWS',
    //     msgTitle: '飲料買一送一',
    //     msgContent: '飲料買一送一活動進行中，歡迎來店使用！',
    //     msgSentTime: '2022-01-31 18:00',
    //   },
    // ],
  });
  const [messageData, setMessageData] = React.useState();
  const [functionOn, setFunctionOn] = React.useState();

  React.useEffect(() => {
    getMessageSentList();
    functionIsOn();
  }, []);
  const getMessageSentList = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.Message.list(storeCode, token).then(res => {
      if (res.data === '沒有訊息紀錄') return;
      setMessageData(res.data);
    });
  };

  const functionIsOn = () => {
    // 檢查是否有Line AccessToken和channel secret來開啟line message推播功能
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.ThirdParty.list(storeCode, token).then(res => {
      if (res.data !== '沒有第三方串接資料') {
        setFunctionOn(true);
      } else {
        setFunctionOn(false);
      }
    });
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.message.list" />} breadcrumbs={breadcrumbs}>
      {functionOn ? (
        <MaterialTable
          className={classes.root}
          icons={TABLE_ICONS}
          title="訊息管理列表"
          columns={state.columns}
          data={messageData}
          options={{
            actionsColumnIndex: -1,
            paging: true,
            pageSize: 10, // make initial page size
            emptyRowsWhenPaging: false, // To avoid of having empty rows
            pageSizeOptions: [6, 12, 20, 50], // rows selection options
          }}
        />
      ) : functionOn === undefined ? (
        ''
      ) : (
        <div>
          <h1>功能尚未啟用，請洽服務人員</h1>
        </div>
      )}
    </PageContainer>
  );
}
