import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import { sidebarNavsStore, sidebarNavsAdmin } from '../menus';
import { useSelector } from 'react-redux';
import jwt from 'jsonwebtoken';
import localStorageService from './../../../../../services/LocalStorageService';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  // const auth = useSelector(({ auth }) => auth);
  // console.log('auth', auth);

  // 依token內角色決定顯示何種選單
  let sidebarNavs = sidebarNavsStore; // 預設選單

  if (localStorageService.isRoleAdmin()) {
    sidebarNavs = sidebarNavsAdmin;
    console.log('[SideBar] do retrieve sidbar menus for system administrator');
  }

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={sidebarNavs} />
    </PerfectScrollbar>
  );
};

export default SideBar;
