import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '使用者AR遊戲曲線圖',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const initialLogData = [['', 0]];
const initialLabelData = ['21/Oct/2022', '24/Oct/2022', '25/Oct/2022', '26/Oct/2022', '27/Oct/2022', '28/Oct/2022'];
const initialHashTable = {
  1234567: '',
};
const hashfn = labels => {
  let hashTable = {};
  labels.map(label => {
    hashTable[new Date(label).setHours(0, 0, 0, 0)] = label;
  });
  return hashTable;
};

const initialFilterDate = {
  start: `${new Date().getFullYear()}-${(new Date().getMonth() - 2).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
  end: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
};
export default function Table() {
  const classes = useStyles();
  const [labels, setLebels] = React.useState(initialLabelData);
  const [logdata, setLogData] = React.useState(initialLogData);
  const [filteredLabel, setFilteredLabel] = React.useState(initialLabelData);
  const [filteredLogdata, setFilteredLogData] = React.useState(initialLogData);
  const [hashTable, setHashTable] = React.useState(initialHashTable);
  React.useEffect(() => {
    getAnalysisLog();
  }, []);

  const getAnalysisLog = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.Game.records(storeCode, token).then(response => {
      const labelsa = [];
      const dataaaa = [];
      response.data.gameRecords.map(item => {
        dataaaa.push(Object.values(item));
        labelsa.push(item.date);
      });
      setLebels(labelsa);
      setFilteredLabel(labelsa);
      setLogData(dataaaa);
      setFilteredLogData(dataaaa);
      const labelHashTable = hashfn(labelsa);
      setHashTable(labelHashTable);
    });
  };

  const data = {
    labels: filteredLabel,
    datasets: [
      {
        label: 'AR小遊戲資訊',
        data: filteredLogdata,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  // 日期輸入
  const [filterDate, setFilterDate] = React.useState(initialFilterDate);
  const handleStartChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, start: event.target.value }));
  };
  const handleEndChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, end: event.target.value }));
  };

  // 過濾搜尋
  const handleClick = event => {
    event.preventDefault();
    setFilteredLabel(labels);
    setFilteredLogData(logdata);
    const startTime = new Date(filterDate.start).setHours(0, 0, 0, 0);
    const endTime = new Date(filterDate.end).setHours(0, 0, 0, 0);
    labelFilter(startTime, endTime);
    dataFilter(startTime, endTime);
  };
  const labelFilter = (startTime, endTime) => {
    // x 時間軸filter
    let filteredDateLabel = labels
      .map(label => new Date(label).setHours(0, 0, 0, 0))
      .filter(item => item >= startTime && item <= endTime);
    filteredDateLabel = filteredDateLabel.map(item => hashTable[item]);
    setFilteredLabel(filteredDateLabel);
  };
  const dataFilter = (startTime, endTime) => {
    const filteredData = logdata.filter(item => {
      // 此層結構 => ['21/Oct/2022', 8]
      const timestamp = new Date(item[0]).setHours(0, 0, 0, 0);
      return timestamp >= startTime && timestamp <= endTime;
    });
    setFilteredLogData(filteredData);
  };

  // 清除
  const handleClickReset = event => {
    event.preventDefault();
    setFilteredLabel(labels);
    setFilteredLogData(logdata);
    setFilterDate(initialFilterDate);
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.chart.ARgame" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section} style={{ height: '80%' }}>
        <h3>
          開始時間: {filterDate.start} 結束時間: {filterDate.end}
        </h3>
        <input type="date" id="start" name="start" onChange={handleStartChange} value={filterDate.start} />
        <input type="date" id="end" name="end" onChange={handleEndChange} value={filterDate.end} />

        <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClick}>
          過濾
        </Button>
        <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClickReset}>
          清除
        </Button>
        <Line data={data} width={600} height={400} options={options} />
      </Box>
    </PageContainer>
  );
}
