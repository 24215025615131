import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '../../../../@jumbo/constants/TableIcons';
import StoreAdminService from 'services/StoreService';
import LocalStorageService from 'services/LocalStorageService';
import apiService from 'services/apiService';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];

export default function Table() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    columns: [
      { title: 'No.', render: rowData => rowData.tableData.id + 1 },
      { title: '會員卡號', field: 'mbrCode' },
      { title: '會員姓名', field: 'mbrName' },
      { title: '會員暱稱', field: 'mbrNick' },
      {
        title: '會員手機號碼',
        render: rowData => (rowData.mbrMP ? rowData.mbrMP : rowData.mbrMobile.length === 10 ? rowData.mbrMobile : ''),
      },
      { title: '電子郵件', field: 'mbrEmail' },
      { title: '啟用狀態', field: 'mbrEnabled', type: 'boolean' },
      { title: '會員來源', field: 'mbrFrom', render: rowData => (rowData.mbrFrom === 'web' ? '網頁會員' : 'LINE會員') },
      { title: '加入日期', field: 'createdAt', type: 'date' },
    ],
    // data: [
    //   {
    //     mbrCode: '12342109013330',
    //     mbrName: '會員333',
    //     mbrMobile: '0911222333',
    //     mbrEmail: 'mbthree@gmail.com',
    //     mbrEnabled: true,
    //   },
    //   {
    //     mbrCode: '12342109015550',
    //     mbrName: '會員555',
    //     mbrMobile: '0911222555',
    //     mbrEmail: 'mbfive@gmail.com',
    //     mbrEnabled: true,
    //   },
    //   {
    //     mbrCode: '12342109017770',
    //     mbrName: '會員777',
    //     mbrMobile: '0911222777',
    //     mbrEmail: 'mbseven@gmail.com',
    //     mbrEnabled: false,
    //   },
    // ],
  });
  const [member, setMember] = React.useState([]);
  React.useEffect(() => {
    getMemberList();
  }, []);
  const getMemberList = () => {
    const storeCode = LocalStorageService.getStoreCode();
    const token = LocalStorageService.getToken();
    StoreAdminService.findAllMembersByStore(storeCode, token).then(response => {
      console.log('response data from member', response.data);
      apiService.log.add(
        storeCode,
        {
          requestUrl: `/store/members/${storeCode}`,
          requestMethod: 'GET',
          responseStatus: response.status,
        },
        token,
      );
      setMember(response.data);
    });
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.member.list" />} breadcrumbs={breadcrumbs}>
      <MaterialTable
        className={classes.root}
        icons={TABLE_ICONS}
        title="會員資料列表"
        columns={state.columns}
        data={member}
        options={{
          actionsColumnIndex: -1,
        }}
        // editable={{
        //   onRowUpdate: (newData, oldData) => new Promise(resolve => {}),
        //   onRowDelete: oldData => new Promise(resolve => {}),
        //   onRowAdd: newData => new Promise(resolve => {}),
        // }}
      />
    </PageContainer>
  );
}
