/**
 * 使用Material UI 創建介面
 * 使用Formik 進行表單處理和驗證
 */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { DateTimePicker } from '@material-ui/pickers';
import { CrossButton } from '../../../Components/buttonStyle';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog({ getBannerList, initialValues }) {
  // console.log('Form Dialog rendering');
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState({ actImg: {}, actBigImg: [], tag: null });
  const [imageUrl, setImageUrl] = React.useState({ actImg: '', actBigImg: [] });

  React.useEffect(() => {
    // console.log('selected image', selectedImage);
    if (selectedImage.tag == 0) {
      //tage = 0 代表小圖
      let imgURL = '';
      if (selectedImage.actImg instanceof File) {
        // console.log('is file');
        imgURL = URL.createObjectURL(selectedImage.actImg);
      } else {
        imgURL = '';
      }
      setImageUrl({
        ...imageUrl,
        actImg: imgURL,
      });
    } else if (selectedImage.tag == 1) {
      //tage = 1 代表大圖
      let imgURL = [];
      // console.log('Big image generating url', selectedImage.actBigImg);
      selectedImage.actBigImg.map(file => {
        imgURL.push(URL.createObjectURL(file));
      });
      setImageUrl(prev => {
        let newState = Object.assign({}, prev); //shallow copy of object
        newState.actBigImg = imgURL;
        return newState;
      });
    }
    if (selectedImage.tag !== null) {
      return () => {
        setSelectedImage(prev => {
          let newState = Object.assign({}, prev); //shallow copy state
          newState.tag = null;
          return newState;
        });
      };
    }
  }, [selectedImage]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const resetState = () => {
    setSelectedImage(prev => {
      let newState = Object.assign({}, prev); //shallow copy state
      newState.actImg = {};
      newState.actBigImg = [];
      newState.tag = null;
      // console.log('handle close:', newState);
      return newState;
    });
    setImageUrl(prev => {
      let newState = Object.assign({}, prev); //shallow copy of object
      newState.actImg = '';
      newState.actBigImg = [];
      return newState;
    });
  };
  const handleClose = () => {
    setOpen(false);
    resetState();
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    setOpen(false);
    let actImgName = '';
    let actBigImgName = [];
    // console.log('selectedImage.actImg', selectedImage.actImg);
    if (selectedImage.actImg instanceof File) {
      // console.log('小');
      await ApiService.Image.upload(storeCode, 'activity', selectedImage.actImg).then(res => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/store/${storeCode}/upload/activity`,
            requestMethod: 'POST',
            responseStatus: res.status,
          },
          token,
        );
        actImgName = res.data.uuidName;
      });
    }
    if (selectedImage.actBigImg.length > 0) {
      // console.log('大');
      for (let item of selectedImage.actBigImg) {
        await ApiService.Image.upload(storeCode, 'activity', item).then(res => {
          ApiService.log.add(
            storeCode,
            {
              requestUrl: `/store/${storeCode}/upload/activity`,
              requestMethod: 'POST',
              responseStatus: res.status,
            },
            token,
          );
          actBigImgName.push(res.data.uuidName);
        });
      }
    }
    const bannerData = {
      actCode: values.actCode,
      actTitle: values.actTitle,
      actSubTitle: values.actSubTitle,
      actDesc: values.actDesc,
      actIsBanner: 1,
      actImage: actImgName,
      actBigImage: actBigImgName,
      actStartTime: values.actStartTime,
      actStopTime: values.actStopTime,
      actStatus: values.actStatus,
      actUrl: values.actUrl,
    };
    // console.log('Banner data', bannerData);
    ApiService.Banner.add(storeCode, bannerData, token).then(async response => {
      ApiService.log.add(
        storeCode,
        {
          requestUrl: `/banner/${storeCode}`,
          requestMethod: 'POST',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        await getBannerList();
      }
    });
    resetState();
  };
  const removeImage = (tag, name) => {
    // change selectedImage state
    if (tag == 0) {
      //判斷小圖
      setSelectedImage({
        ...selectedImage,
        actImg: '',
        tag: 0,
      });
    } else if (tag == 1) {
      //判斷大圖
      // console.log(`Delete the big image ${name}`);
      let img = selectedImage.actBigImg.filter(item => {
        return item.name !== name;
      });
      // console.log('img array filter', img);
      setSelectedImage({
        ...selectedImage,
        actBigImg: img,
        tag: 1,
      });
    }
  };
  const validationSchema = yup.object().shape({
    actCode: yup.string('Activity Code').required('必填欄位'),
    actTitle: yup.string('Activity Name').required('必填欄位'),
  });
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>新增Banner</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>請填入下列資訊</DialogContentText>
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs="6">
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="actCode"
                        name="actCode"
                        label="Banner代碼"
                        type="text"
                        fullWidth
                        disabled
                        value={values.actCode}
                        onChange={handleChange}
                        error={touched.actCode && Boolean(errors.actCode)}
                        helperText={touched.actCode && errors.actCode}
                      />
                      <Field
                        required
                        as={TextField}
                        autoFocus
                        margin="dense"
                        id="actTitle"
                        name="actTitle"
                        label="Banner標題"
                        type="text"
                        fullWidth
                        value={values.actTitle}
                        onChange={handleChange}
                        error={touched.actTitle && Boolean(errors.actTitle)}
                        helperText={touched.actTitle && errors.actTitle}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="actSubTitle"
                        name="actSubTitle"
                        label="Banner副標題"
                        type="text"
                        fullWidth
                        multiline
                        value={values.actSubTitle}
                        onChange={handleChange}
                        error={touched.actSubTitle && Boolean(errors.actSubTitle)}
                        helperText={touched.actSubTitle && errors.actSubTitle}
                      />
                      <Field
                        as={TextField}
                        defaultValue="Activity Description defaut content."
                        margin="dense"
                        id="actDesc"
                        name="actDesc"
                        label="Banner說明"
                        type="text"
                        fullWidth
                        value={values.actDesc}
                        onChange={handleChange}
                        error={touched.actDesc && Boolean(errors.actDesc)}
                        helperText={touched.actDesc && errors.actDesc}
                      />
                      <Field
                        as={FormControl}
                        margin="dense"
                        id="actImage"
                        name="actImage"
                        label="Banner小圖"
                        value={values.actImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-single-image"
                          style={{ display: 'none' }}
                          onChange={e =>
                            setSelectedImage({
                              ...selectedImage,
                              actImg: e.target.files[0],
                              tag: 0,
                            })
                          }
                        />
                        <label htmlFor="select-single-image">
                          <Button variant="contained" color="primary" component="span">
                            Banner小圖
                          </Button>
                          <p>(圖片格式: 429px * 429px, 1張)</p>
                        </label>
                      </Field>
                      {imageUrl.actImg && selectedImage.actImg ? (
                        <Box mt={2} textAlign="center">
                          <div style={{ position: 'relative', width: '150px', margin: 'auto' }}>
                            <div>小圖預覽:</div>
                            <img src={imageUrl.actImg} alt={selectedImage.actImg.name} height="150px" />
                            <CrossButton onClick={() => removeImage(0, selectedImage.actImg.name)}>X</CrossButton>
                          </div>
                        </Box>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs="6">
                      <DateTimePicker
                        margin="dense"
                        id="actStartTime"
                        name="actStartTime"
                        label="Banner開始時間"
                        value={values.actStartTime}
                        onChange={val => {
                          // console.log('picked activity start time', val.format());
                          setFieldValue('actStartTime', val);
                        }}
                        renderInput={params => <TextField {...params} />}
                      />
                      <DateTimePicker
                        margin="dense"
                        id="actStopTime"
                        name="actStopTime"
                        label="Banner結束時間"
                        value={values.actStopTime}
                        onChange={val => {
                          // console.log('picked activity stop time', val.format());
                          setFieldValue('actStopTime', val);
                        }}
                        renderInput={params => <TextField {...params} />}
                      />
                      <Field
                        as={FormControlLabel}
                        margin="dense"
                        control={<Checkbox id="actStatus" name="actStatus" />}
                        label="Banner狀態"
                        checked={values.actStatus}
                        onChange={handleChange}
                      />
                      <Field
                        fullWidth
                        as={FormControl}
                        margin="dense"
                        id="actBigImage"
                        name="actBigImage"
                        label="Banner大圖"
                        value={values.actBigImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-multi-image"
                          style={{ display: 'none' }}
                          onChange={e => {
                            if (e.target.files[0] !== undefined && selectedImage.actBigImg.length < 3) {
                              setSelectedImage(prev => {
                                // console.log('大圖', e.target.files[0]);
                                let newState = Object.assign({}, prev); //shallow copy state
                                newState.actBigImg.push(e.target.files[0]);
                                newState.tag = 1;
                                return newState;
                              });
                            }
                          }}
                        />
                        <label htmlFor="select-multi-image">
                          <Button variant="contained" color="primary" component="span">
                            Banner大圖
                          </Button>
                          <p>(圖片格式: 960px * 480px, 最多3張圖)</p>
                        </label>
                      </Field>
                      {imageUrl.actBigImg.length > 0 &&
                      selectedImage.actBigImg.length > 0 &&
                      imageUrl.actBigImg.length == selectedImage.actBigImg.length ? (
                        <Grid container spacing={2}>
                          {imageUrl.actBigImg.map((imgUrl, index) => {
                            return (
                              <Grid key={index}>
                                <Box mt={2} textAlign="center" style={{ position: 'relative' }}>
                                  <div>大圖預覽{index + 1}:</div>
                                  <img src={imgUrl} alt={selectedImage.actBigImg[index].name} height="100px" />
                                  <CrossButton onClick={() => removeImage(1, selectedImage.actBigImg[index].name)}>
                                    X
                                  </CrossButton>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定新增
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add New
      </Button>
    </Box>
  );
}
