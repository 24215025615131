import React from 'react';
import { PostAdd, InsertChart, ImportContacts, Group, Domain, LibraryBooks, LibraryAdd } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';

// [選單]系統管理者
export const sidebarNavsAdmin = [
  {
    // 儀表版
    name: <IntlMessages id={'sidebar.dashboards'} />,
    type: 'section',
    children: [
      {
        // 首頁
        name: <IntlMessages id={'pages.firstPage'} />,
        type: 'item',
        icon: <InsertChart />,
        link: '/first-page',
      },
      /*{
        // Dashboard
        name: <IntlMessages id={'pages.dashboard'} />,
        type: 'item',
        icon: <InsertChart />,
        link: '/dashboard/crm',
      },*/
    ],
  },
  {
    // 店家資料
    name: <IntlMessages id={'sidebar.stores'} />,
    type: 'section',
    children: [
      {
        // 店家資料列表
        name: <IntlMessages id={'sidebar.store.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/store/list',
      },
      {
        // 新增店家
        name: <IntlMessages id={'sidebar.store.create'} />,
        type: 'item',
        icon: <LibraryAdd />,
        link: '/store/create',
      },
    ],
  },
  {
    // 報表管理
    name: <IntlMessages id={'sidebar.chart'} />,
    type: 'section',
    children: [
      {
        // 後台報表
        name: <IntlMessages id={'sidebar.chart.cms'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/chart/cms',
      },
    ],
  },
];

// [選單]店家管理者
export const sidebarNavsStore = [
  {
    // 儀表版
    name: <IntlMessages id={'sidebar.dashboards'} />,
    type: 'section',
    children: [
      {
        // 首頁
        name: <IntlMessages id={'pages.firstPage'} />,
        type: 'item',
        icon: <InsertChart />,
        link: '/first-page',
      },
      {
        // 店家資訊
        name: <IntlMessages id={'sidebar.store.info'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/store/info',
      },
      /*{
        // Dashboard
        name: <IntlMessages id={'pages.dashboard'} />,
        type: 'item',
        icon: <InsertChart />,
        link: '/dashboard/crm',
      },*/
    ],
  },
  /*{
    // 店家資料
    name: <IntlMessages id={'sidebar.stores'} />,
    type: 'section',
    children: [
      {
        // 店家資料列表
        name: <IntlMessages id={'sidebar.store.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/store/list',
      },
      {
        // 新增店家
        name: <IntlMessages id={'sidebar.store.create'} />,
        type: 'item',
        icon: <LibraryAdd />,
        link: '/store/create',
      },
    ],
  },*/
  {
    // 帳號資料
    name: <IntlMessages id={'sidebar.store.accounts'} />,
    type: 'section',
    children: [
      {
        // 帳號資料列表
        name: <IntlMessages id={'sidebar.store.account.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/store/account/list',
      },
      // {
      //   // 新增帳號
      //   name: <IntlMessages id={'sidebar.store.account.create'} />,
      //   type: 'item',
      //   icon: <LibraryAdd />,
      //   link: '/store/account/create',
      // },
    ],
  },
  {
    // 商品資料
    name: <IntlMessages id={'sidebar.product'} />,
    type: 'section',
    children: [
      {
        // 商品資料列表
        name: <IntlMessages id={'sidebar.product.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/product/list',
      },
      // {
      //   // 新增商品
      //   name: <IntlMessages id={'sidebar.product.create'} />,
      //   type: 'item',
      //   icon: <LibraryAdd />,
      //   link: '/product/create',
      // },
    ],
  },
  {
    // 分類資料
    name: <IntlMessages id={'sidebar.category'} />,
    type: 'section',
    children: [
      {
        // 分類資料列表
        name: <IntlMessages id={'sidebar.category.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/category/list',
      },
    ],
  },
  {
    // 最新活動
    name: <IntlMessages id={'sidebar.activity.banner.zero'} />,
    type: 'section',
    children: [
      {
        // 最新活動列表
        name: <IntlMessages id={'sidebar.activity.banner.zero.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/activity/banner/zero/list',
      },
    ],
  },
  {
    // Banner資料
    name: <IntlMessages id={'sidebar.activity.banner.one'} />,
    type: 'section',
    children: [
      {
        // Banner資料列表
        name: <IntlMessages id={'sidebar.activity.banner.one.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/activity/banner/one/list',
      },
    ],
  },
  {
    // 集點卡資料
    name: <IntlMessages id={'sidebar.pointcard'} />,
    type: 'section',
    children: [
      {
        // 集點卡資料列表
        name: <IntlMessages id={'sidebar.pointcard.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/pointcard/list',
      },
      /*{
        // 集點卡使用記錄列表
        name: <IntlMessages id={'sidebar.pointcard.collect.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/pointcard/collect/list',
      },*/
    ],
  },
  {
    // 優惠券資料
    name: <IntlMessages id={'sidebar.coupon'} />,
    type: 'section',
    children: [
      {
        // 優惠券資料列表
        name: <IntlMessages id={'sidebar.coupon.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/coupon/list',
      },
      {
        // 優惠群組資料列表
        name: <IntlMessages id={'sidebar.coupon.group.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/coupon/group/list',
      },
      {
        // 優惠券使用記錄列表
        name: <IntlMessages id={'sidebar.coupon.usage.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/coupon/usage/list',
      },
    ],
  },
  {
    // 訂單記錄
    name: <IntlMessages id={'sidebar.order'} />,
    type: 'section',
    children: [
      {
        // 訂單記錄列表
        name: <IntlMessages id={'sidebar.order.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/order/list',
      },
    ],
  },
  {
    // 訊息管理
    name: <IntlMessages id={'sidebar.message'} />,
    type: 'section',
    children: [
      {
        // 訊息管理列表
        name: <IntlMessages id={'sidebar.message.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/message/list',
      },
      {
        // 新增訊息
        name: <IntlMessages id={'sidebar.message.create'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/message/add',
      },
    ],
  },
  {
    // 報表管理
    name: <IntlMessages id={'sidebar.chart'} />,
    type: 'section',
    children: [
      {
        // 使用者報表
        name: <IntlMessages id={'sidebar.chart.frontend'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/chart/frontend',
      },
      {
        // AR遊戲報表
        name: <IntlMessages id={'sidebar.chart.ARgame'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/chart/argame',
      },
    ],
  },
  {
    // 會員資料
    name: <IntlMessages id={'sidebar.member'} />,
    type: 'section',
    children: [
      {
        // 會員資料列表
        name: <IntlMessages id={'sidebar.member.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/member/list',
      },
      {
        // 資料下載
        name: <IntlMessages id={'sidebar.member.download'} />,
        type: 'item',
        icon: <LibraryAdd />,
        link: '/member/download',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];
