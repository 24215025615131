/**
 * 使用Material UI 創建介面
 * 使用Formik 進行表單處理和驗證
 */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog(props) {
  // console.log('Form Dialog rendering');
  const { open, setIsDialogOpen, children, rowData, getMemberList, getStoreTags, ...others } = props;
  // const [open, setOpen] = React.useState(false);
  const [tagsFlag, setTagsFlag] = React.useState({});

  React.useEffect(() => {
    // console.log('rowData', rowData)
    // if (rowData[0] === undefined) return
    if (rowData.mbrTag !== undefined && rowData.mbrTag !== null) {
      const tags = JSON.parse(rowData.mbrTag);
      const flag = {};
      tags.map(tag => (flag[`${tag}`] = true));
      // console.log('flag', flag)
      setTagsFlag(flag);
    }
  }, [rowData]);
  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    // console.log('fn/ isAccessTokenValid', validToken.isValid)
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    setIsDialogOpen(false);
    const tagFiltered = Object.keys(Object.fromEntries(Object.entries(tagsFlag).filter(([key, value]) => value === true)));
    // console.log('tag filtered', tagFiltered)
    // console.log('rowData', rowData)
    ApiService.Tag.modify(storeCode, token, {
      memberId: rowData.id,
      tag: JSON.stringify(tagFiltered),
    })
      .then(res => {
        getMemberList();
        getStoreTags();
      })
      .catch(err => console.log('reject ', err));
  };
  return (
    <Box>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth="true">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <h3>編輯標籤</h3>
        </DialogTitle>
        <DialogContent dividers>
          <Grid>
            <DialogContentText>選取要刪除標籤</DialogContentText>
            {rowData.mbrTag !== undefined && rowData.mbrTag !== null
              ? JSON.parse(rowData.mbrTag).map(tag => {
                  if (tag.length !== 0)
                    return (
                      <Button
                        color={tagsFlag[tag] ? 'primary' : 'secondary'}
                        variant="contained"
                        style={{
                          borderRadius: '20px',
                          margin: '2px',
                        }}
                        onClick={() => {
                          setTagsFlag(prevState => {
                            const state = prevState;
                            state[tag] = !state[tag];
                            return { ...state };
                          });
                        }}>
                        {tag}
                      </Button>
                    );
                })
              : '沒有標籤資訊'}
          </Grid>
          <form onSubmit={handleSubmit}>
            <DialogActions>
              <Button type="submit" color="primary">
                確定變更
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
