/**
 * 使用Material UI 創建介面
 * 使用Formik 進行表單處理和驗證
 */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog({ messageData, initialValues, open, setOpen, onClose, quotaData }) {
  // console.log('Form Dialog rendering');
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    // console.log('fn/ isAccessTokenValid', validToken.isValid)
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    setOpen(false);
    if (messageData.msgCode) {
      const messageDataData = {
        message: {
          messageCode: messageData.msgCode,
          subject: values.subject,
          messageBody: values.messageBody,
        },
        userIds: messageData.memberIds,
      };
      console.log('messageDataData', messageDataData);
      ApiService.Message.add(storeCode, token, messageDataData)
        .then(response => {
          // console.log('send message response', response);
          alert('發送訊息成功');
        })
        .catch(err => {
          alert('發送訊息失敗請稍後再試', err);
        });
    }
  };
  const validationSchema = yup.object().shape({
    subject: yup.string('Subject').required('必填欄位'),
    messageBody: yup.string('Message Body').required('必填欄位'),
  });
  return (
    <Box>
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span>新增訊息</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>請填入下列資訊 已選擇{messageData.memberIds.length}位會員</DialogContentText>
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const { values, touched, errors, dirty, isSubmitting, handleChange, handleSubmit, handleReset } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    required
                    as={TextField}
                    margin="dense"
                    id="subject"
                    name="subject"
                    label="訊息標題"
                    type="text"
                    fullWidth
                    value={values.subject}
                    onChange={handleChange}
                    error={touched.subject && Boolean(errors.subject)}
                    helperText={touched.subject && errors.subject}
                  />
                  <Field
                    as={TextField}
                    margin="dense"
                    id="messageBody"
                    name="messageBody"
                    label="訊息內容"
                    multiline
                    fullWidth
                    value={values.messageBody}
                    onChange={handleChange}
                    error={touched.messageBody && Boolean(errors.messageBody)}
                    helperText={touched.messageBody && errors.messageBody}
                  />
                  <span>每月免費推播次數: {quotaData.monthLimits} 則, </span>
                  <span>本月已推播次數: {quotaData.totalUsage} 則</span>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定新增
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
         Add New
       </Button> */}
    </Box>
  );
}
