import React, { forwardRef, useEffect } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Visibility from '@material-ui/icons/Visibility';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import EditFormDialog from './EditFormDialog';
import localStorageService from 'services/LocalStorageService';
import ApiService from 'services/apiService';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
};
export default function ProductDataTable({ activity, getActivityList }) {
  // console.log('Data Table rendering');
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState({});
  const [state, setState] = React.useState({
    columns: [
      { title: 'No.', render: rowData => rowData.tableData.id + 1 },
      { title: '活動代碼', field: 'actCode' },
      { title: '活動標題', field: 'actTitle' },
      { title: '活動子標題', field: 'actSubTitle' },
      { title: '活動開始時間', field: 'actStartTime', type: 'date' },
      { title: '活動結束時間', field: 'actStopTime', type: 'date' },
      { title: '活動狀態', field: 'actStatus', type: 'boolean' },
    ],
    data: [],
  });
  const classes = useStyles();

  const openPopup = rowData => {
    setRowData({ ...rowData });
    setIsDialogOpen(true);
  };
  const actionsFn = rowData => {
    const expiredTime = new Date(rowData.actStopTime).getTime();
    const now = new Date().getTime();
    expiredTime < now ? (rowData.expired = true) : (rowData.expired = false);
    return {
      icon: () => (expiredTime < now ? <Visibility /> : <Edit />),
      tooltip: 'Edit the data',
      onClick: (event, rowData) => {
        openPopup(rowData);
      },
    };
  };
  const removeActivity = async actCode => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    await ApiService.Activity.remove(storeCode, actCode, token)
      .then(response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/activity/remove/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status == 200) {
          getActivityList();
        } else {
          console.log('Activity', 'Activity/delete response:' + JSON.stringify(response.data));
        }
      })
      .catch(err => {
        console.log('error', err);
      });
  };
  return (
    <div>
      <MaterialTable
        className={classes.root}
        icons={tableIcons}
        title="活動資料列表"
        columns={state.columns}
        data={activity}
        options={{
          actionsColumnIndex: -1,
        }}
        actions={[rowData => actionsFn(rowData)]}
        editable={{
          onRowDelete: oldData => removeActivity(oldData.actCode),
        }}
      />
      <EditFormDialog
        open={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        rowData={rowData}
        getActivityList={getActivityList}
      />
    </div>
  );
}
