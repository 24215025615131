import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import FormDialog from './FormDialog';
import newNameCode from 'services/nameCodeGenerator';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

let currentDate = new Date();
const initialValues = {
  actCode: '',
  actTitle: '',
  actSubTitle: '',
  actDesc: '',
  actIsBanner: 0,
  actImage: '',
  actBigImage: [''],
  actStartTime: currentDate.toString(),
  actStopTime: currentDate.setDate(currentDate.getDate() + 7),
  actStatus: true,
  actUrl: '',
};
export default function Table() {
  const classes = useStyles();
  const [activity, setActivity] = React.useState();
  React.useEffect(() => {
    getActivityList();
  }, []);
  const getActivityList = async () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Activity.list(storeCode)
      .then(async response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/v1/activity/list/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          setActivity(response.data.activityList);
          newName('ACT', response.data.activityList);
        } else {
          console.log('activity', 'activity/list response:' + JSON.stringify(response.data));
        }
      })
      .catch(err => console.log('err', err));
  };
  const newName = (prefix, dataList) => {
    if (dataList != undefined) {
      let nameCodeDB = dataList.map(dataInstance => {
        return dataInstance.actCode;
      });
      initialValues.actCode = newNameCode(prefix, nameCodeDB);
    }
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.activity.banner.zero.list" />}>
      <Box className={classes.section}>
        <DataTable activity={activity} getActivityList={getActivityList} />
      </Box>
      <Box className={classes.section}>
        <FormDialog activity={activity} initialValues={initialValues} getActivityList={getActivityList} />
      </Box>
    </PageContainer>
  );
}
