import jwt from 'jsonwebtoken';

const getToken = () => {
  return localStorage.getItem('token');
};

const decodeToken = token => {
  if (!token) {
    token = getToken();
  }
  if (token) {
    const tokenDecoded = jwt.decode(token, { complete: true });
    console.log('[LocalStorageService] tokenDecoded', tokenDecoded);
    return tokenDecoded;
  } else {
    return undefined;
  }
};

const isRoleAdmin = () => {
  //   const token = localStorage.getItem('token');
  const token = getToken();
  if (!token) {
    return false;
  }

  //   const tokenDecoded = jwt.decode(token, { complete: true });
  const tokenDecoded = decodeToken(token);
  if (!tokenDecoded) {
    return false;
  }

  if (tokenDecoded && tokenDecoded.payload.roles.includes('ROLE_ADMIN')) {
    console.log('[LocalStorageService] current user is sys admin');
    return true;
  }
  return false;
};

const getUserEmail = () => {
  const tokenDecoded = decodeToken();
  if (tokenDecoded) {
    return tokenDecoded.payload.adminEmail;
  } else {
    return undefined;
  }
};

const getStoreCode = () => {
  const tokenDecoded = decodeToken();
  if (tokenDecoded) {
    return tokenDecoded.payload.storeCode;
  } else {
    return undefined;
  }
};

const getStoreName = () => {
  const tokenDecoded = decodeToken();
  if (tokenDecoded) {
    return tokenDecoded.payload.storeName;
  } else {
    return undefined;
  }
};

const LocalStorageService = {
  getToken,
  isRoleAdmin,
  getUserEmail,
  getStoreCode,
  getStoreName,
};

export default LocalStorageService;
