/**
 * 使用Formik 進行表單處理和驗證
 */
import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { DateTimePicker } from '@material-ui/pickers';
import { CrossButton } from '../../../Components/buttonStyle';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function EditFormDialog(props) {
  const { open, setIsDialogOpen, children, rowData, initialValues, getActivityList, ...others } = props;
  const [data, setData] = React.useState(initialValues); //呈現表格用
  const [selectedImage, setSelectedImage] = React.useState({ actImg: {}, actBigImg: [], tag: null }); //新照片儲存file用
  const [imageUrl, setImageUrl] = React.useState({ actImg: '', actBigImg: [] }); //預覽圖片畫面呈現用
  const [imgName, setImgName] = React.useState({ actImg: '', actBigImg: [] }); //更新資料庫用

  useEffect(() => {
    //將傳進來的rowData分別存進 data, imageUrl, imgName
    if (rowData.id !== undefined) {
      //1.存進data 呈現表單資料
      setData({
        ...rowData,
      });
      //2.存進imageUrl, 呈現預覽畫面使用
      setImageUrl({
        ...imageUrl,
        actImg: rowData.actImage,
        actBigImg: rowData.actBigImage,
      });
      //3.存進imgName，分析DB回傳圖片url,抽出檔名。 更新回存DB使用
      let actImageName_DB = '';
      let actBigImgName_DB = [];
      if (rowData.actImage) {
        actImageName_DB = new URL(rowData.actImage).pathname.split('/').slice(-1)[0]; //分析DB回傳URL->取得檔名->存字串
      }
      if (rowData.actBigImage.length > 0) {
        actBigImgName_DB = rowData.actBigImage.map(bigImgURL => {
          //分析URL->取得檔名
          return new URL(bigImgURL).pathname.split('/').slice(-1)[0];
        });
      }
      setImgName({
        ...imgName,
        actImg: actImageName_DB,
        actBigImg: actBigImgName_DB,
      });
      // console.log('step 1 / rowData / ', rowData);
      // console.log('step 1 / rowData / set / actimg URL', rowData.actImage);
      // console.log('step 1 / rowData / set / actBigimg URL', rowData.actBigImage);
      // console.log('step 1 / rowData / set /actImageName', actImageName_DB);
      // console.log('step 1 / rowData / set /actBigImgUrlName', actBigImgName_DB);
    }
  }, [rowData]);
  useEffect(() => {
    // 處理imgURL, imgName state;
    // 當selectedImg有資料取得URL, 存進imgURL
    // 當selectedImg沒有資料,
    // console.log('selected image', selectedImage);
    if (selectedImage.tag == 0) {
      //tage = 0 代表小圖
      let imgURL = '';
      // console.log(
      //   'step 2/ selectImage change / tag 0 / small image /selectedImage.actImg is file?',
      //   selectedImage.actImg instanceof File,
      // );
      if (selectedImage.actImg instanceof File) {
        imgURL = URL.createObjectURL(selectedImage.actImg);
        // console.log('step 2/ selectImage change / tag 0 / 產生小圖URL', imgURL);
      } else {
        imgURL = '';
        // console.log('step 2/ selectImage change / 刪除小圖 / 小圖URL / 空', imgURL);
      }
      setImageUrl({
        ...imageUrl,
        actImg: imgURL,
      });
    } else if (selectedImage.tag == 1) {
      //tage = 1 代表大圖
      //取得selected image url 存進 imgurl
      //
      // console.log('step 2 / add / before / selectedImage', selectedImage);
      // console.log('step 2 / add / before / imageUrl', imageUrl);
      // console.log('step 2 / add / before / imgName', imgName);
      let originImgURL = imageUrl.actBigImg;
      let ImgURL = [];
      if (originImgURL.length === 0) {
        // console.log('step 3 / img big url / length', imageUrl.actBigImg.length);
        // console.log('step 3 / imgUrl length = 0 / generating url / selectedImage', selectedImage);
        // console.log('step 3 / imgUrl length = 0 / generating url / before / img Url', imageUrl.actBigImg);
        ImgURL.push(URL.createObjectURL(selectedImage.actBigImg[0]));
      } else if (originImgURL.length < 4) {
        // console.log('step 3 / img big url / length', imageUrl.actBigImg.length);
        // console.log('step 3 / imgUrl length < 4 / generating url / selectedImage', selectedImage);
        // console.log('step 3 / imgUrl length < 4 / generating url / before / img Url', imageUrl.actBigImg);
        ImgURL.push(URL.createObjectURL(selectedImage.actBigImg.slice(-1)[0]));
      } else {
        alert('最多三張圖');
      }
      originImgURL = originImgURL.concat(ImgURL);
      // console.log('step 4 / img big url / length', originImgURL.length);
      // console.log('step 4 / generating url / after / img Url', originImgURL);
      setImageUrl({
        ...imageUrl,
        actBigImg: originImgURL,
      });
    }
    if (selectedImage.tag !== null) {
      return () => {
        setSelectedImage(prev => {
          let newState = Object.assign({}, prev); //shallow copy state
          newState.tag = null;
          return newState;
        });
      };
    }
  }, [selectedImage]);
  const resetState = () => {
    setSelectedImage(prev => {
      let newState = Object.assign({}, prev); //shallow copy state
      newState.actImg = {};
      newState.actBigImg = [];
      newState.tag = null;
      // console.log('handle close:', newState);
      return newState;
    });
    setImageUrl(prev => {
      let newState = Object.assign({}, prev); //shallow copy of object
      newState.actImg = '';
      newState.actBigImg = [];
      return newState;
    });
  };
  const handleClose = () => {
    setIsDialogOpen(false);
    resetState();
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    // console.log('[Edit Form Dialog / handle submit]');
    setIsDialogOpen(false);
    let actImgName = imgName.actImg;
    let actBigImgName = imgName.actBigImg;
    // console.log('submit selected state', selectedImage);
    // console.log('submit imgName state', imgName);
    if (selectedImage.actImg instanceof File) {
      // console.log('selected image updload');
      await ApiService.Image.upload(storeCode, 'activity', selectedImage.actImg).then(res => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/store/${storeCode}/upload/activity`,
            requestMethod: 'POST',
            responseStatus: res.status,
          },
          token,
        );
        actImgName = res.data.uuidName;
        // console.log('產生小圖uuid', actImgName);
      });
    }
    if (selectedImage.actBigImg.length > 0) {
      for (let item of selectedImage.actBigImg) {
        await ApiService.Image.upload(storeCode, 'activity', item).then(res => {
          ApiService.log.add(
            storeCode,
            {
              requestUrl: `/store/${storeCode}/upload/activity`,
              requestMethod: 'POST',
              responseStatus: res.status,
            },
            token,
          );
          actBigImgName.push(res.data.uuidName);
        });
      }
    } else {
      actBigImgName = actBigImgName;
    }
    setImgName({
      ...imgName,
      actImg: actImgName,
      actBigImg: actBigImgName,
    });
    const activityData = {
      actCode: values.actCode,
      actTitle: values.actTitle,
      actSubTitle: values.actSubTitle,
      actDesc: values.actDesc,
      actIsBanner: 0,
      actImage: actImgName,
      actBigImage: actBigImgName,
      actStartTime: values.actStartTime,
      actStopTime: values.actStopTime,
      actStatus: values.actStatus,
      actUrl: values.actUrl,
    };
    // console.log('activity data', activityData);
    ApiService.Activity.modify(storeCode, activityData, token).then(async response => {
      ApiService.log.add(
        storeCode,
        {
          requestUrl: `/activity/${storeCode}`,
          requestMethod: 'PUT',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        await getActivityList();
      }
    });
    resetState();
  };
  const removeImage = (tag, name) => {
    // change selectedImage state
    // console.log('step 2 / remove / before / selectedImage', selectedImage);
    // console.log('step 2 / remove / before / imageUrl', imageUrl);
    // console.log('step 2 / remove / before / imgName', imgName);
    if (tag == 0) {
      //判斷小圖
      //1.清空selectedImage
      setSelectedImage({
        ...selectedImage,
        actImg: '',
        tag: 0,
      });
      setImgName({
        ...imgName,
        actImg: '',
      });
    } else if (tag == 1) {
      //判斷大圖
      // console.log(`Delete the big image ${name}`);
      // console.log('判斷大圖', imgName.actBigImg);
      let index = imgName.actBigImg.findIndex(item => {
        return item == name;
      });
      // console.log('index', index);
      let act_big_image_splice = imageUrl.actBigImg;
      act_big_image_splice.splice(index, 1);
      let filtered_name = imgName.actBigImg.filter((item, index) => {
        // console.log(`index ${index} name => ${item}`);
        return item !== name;
      });

      setImgName({
        ...imgName,
        actBigImg: filtered_name,
      });
      setImageUrl({
        ...imageUrl,
        actBigImg: act_big_image_splice,
      });

      // console.log('img name filtered', filtered_name);
      // console.log('img url after', act_big_image_splice);
    }
  };
  const validationSchema = yup.object().shape({
    actCode: yup.string('Activity Code').required('必填欄位'),
    actTitle: yup.string('Activity Name').required('必填欄位'),
  });
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>修改活動</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>修改下列資訊</DialogContentText>
          <Formik initialValues={data} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs="6">
                      <Field
                        required
                        as={TextField}
                        autoFocus
                        margin="dense"
                        id="actCode"
                        name="actCode"
                        label="活動代碼"
                        type="text"
                        fullWidth
                        disabled
                        value={values.actCode}
                        onChange={handleChange}
                        error={touched.actCode && Boolean(errors.actCode)}
                        helperText={touched.actCode && errors.actCode}
                      />
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="actTitle"
                        name="actTitle"
                        label="活動標題"
                        type="text"
                        fullWidth
                        disabled={rowData.expired}
                        value={values.actTitle}
                        onChange={handleChange}
                        error={touched.actTitle && Boolean(errors.actTitle)}
                        helperText={touched.actTitle && errors.actTitle}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="actSubTitle"
                        name="actSubTitle"
                        label="活動副標題"
                        type="text"
                        fullWidth
                        disabled={rowData.expired}
                        multiline
                        value={values.actSubTitle}
                        onChange={handleChange}
                        error={touched.actSubTitle && Boolean(errors.actSubTitle)}
                        helperText={touched.actSubTitle && errors.actSubTitle}
                      />
                      <Field
                        as={TextField}
                        defaultValue="Activity Description defaut content."
                        margin="dense"
                        id="actDesc"
                        name="actDesc"
                        label="活動說明"
                        type="text"
                        fullWidth
                        disabled={rowData.expired}
                        multiline
                        value={values.actDesc}
                        onChange={handleChange}
                        error={touched.actDesc && Boolean(errors.actDesc)}
                        helperText={touched.actDesc && errors.actDesc}
                      />
                      <Field
                        as={FormControl}
                        margin="dense"
                        id="actImage"
                        name="actImage"
                        label="活動小圖"
                        value={values.actImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-single-image"
                          style={{ display: 'none' }}
                          disabled={rowData.expired}
                          onChange={e =>
                            setSelectedImage({
                              ...selectedImage,
                              actImg: e.target.files[0],
                              tag: 0,
                            })
                          }
                        />
                        <label htmlFor="select-single-image">
                          <Button variant="contained" color="primary" component="span" disabled={rowData.expired}>
                            活動小圖
                          </Button>
                          <p>(圖片格式: 429px * 429px, 1張)</p>
                        </label>
                      </Field>
                      {imageUrl.actImg && selectedImage.actImg ? (
                        <Box mt={2} textAlign="center">
                          <div style={{ position: 'relative', width: '150px', margin: 'auto' }}>
                            <div>小圖預覽:</div>
                            <img src={imageUrl.actImg} alt={selectedImage.actImg.name} height="150px" />
                            <CrossButton
                              type="button"
                              disabled={rowData.expired}
                              onClick={() => removeImage(0, imgName.actImg)}>
                              &times;
                            </CrossButton>
                          </div>
                        </Box>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs="6">
                      <DateTimePicker
                        margin="dense"
                        id="actStartTime"
                        name="actStartTime"
                        label="活動開始時間"
                        value={values.actStartTime}
                        disabled={rowData.expired}
                        onChange={val => {
                          // console.log('picked activity start time', val.format());
                          setFieldValue('actStartTime', val);
                        }}
                      />
                      <DateTimePicker
                        margin="dense"
                        id="actStopTime"
                        name="actStopTime"
                        label="活動結束時間"
                        value={values.actStopTime}
                        disabled={rowData.expired}
                        onChange={val => {
                          // console.log('picked activity stop time', val.format());
                          setFieldValue('actStopTime', val);
                        }}
                        renderInput={params => <TextField {...params} />}
                      />
                      <Field
                        as={FormControlLabel}
                        margin="dense"
                        control={<Checkbox id="actStatus" name="actStatus" />}
                        label="活動狀態"
                        checked={values.actStatus}
                        disabled={rowData.expired}
                        onChange={handleChange}
                      />
                      <Field
                        fullWidth
                        as={FormControl}
                        margin="dense"
                        id="actBigImage"
                        name="actBigImage"
                        label="商品大圖"
                        value={values.actBigImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-multi-image"
                          style={{ display: 'none' }}
                          disabled={rowData.expired}
                          onChange={e => {
                            if (e.target.files[0] !== undefined && imageUrl.actBigImg.length < 3) {
                              setSelectedImage(prev => {
                                let newState = Object.assign({}, prev); //shallow copy state
                                newState.actBigImg.push(e.target.files[0]);
                                newState.tag = 1;
                                return newState;
                              });
                            }
                          }}
                        />
                        <label htmlFor="select-multi-image">
                          <Button variant="contained" color="primary" component="span" disabled={rowData.expired}>
                            商品大圖
                          </Button>
                          <p>(圖片格式: 960px * 480px, 最多3張圖)</p>
                        </label>
                      </Field>
                      {imageUrl.actBigImg.length > 0 ? (
                        <Grid container spacing={2}>
                          {/* {console.log('UI/ big imgage / image URL', imageUrl)}
                          {console.log('UI/ big imgage / image Name', imgName)} */}
                          {imageUrl.actBigImg.map((imgUrl, index) => {
                            return (
                              <Grid key={index}>
                                <Box mt={2} textAlign="center" style={{ position: 'relative' }}>
                                  <div>大圖預覽{index + 1}:</div>
                                  <img src={imgUrl} alt={`Image ${index + 1}`} height="100px" position="relative" />
                                  <CrossButton
                                    type="button"
                                    class="close"
                                    disabled={rowData.expired}
                                    onClick={() => removeImage(1, imgName.actBigImg[index])}>
                                    &times;
                                  </CrossButton>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    {rowData.expired ? (
                      <Button type="submit" color="primary" disabled={true}>
                        確定修改
                      </Button>
                    ) : (
                      <Button type="submit" color="primary" disabled={isSubmitting}>
                        確定修改
                      </Button>
                    )}
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
