import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveStores, updateStore } from '../../../../redux/actions/StoreAction';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Store from '@material-ui/icons/Store';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import Storefront from '@material-ui/icons/Storefront';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ThirdPartyDialog from './ThirdPartyDialog';
import GameBelongDialog from './GameBelongDialog';
import localStorageService from 'services/LocalStorageService';
import ApiService from 'services/apiService';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ThirdPartySetting: forwardRef((props, ref) => <SettingsApplicationsIcon {...props} ref={ref} />),
  StoreFrontSetting: forwardRef((props, ref) => <Storefront {...props} ref={ref} />),
};
const getThirdPartyData = storeCode =>
  new Promise((resolve, reject) => {
    const accessToken = localStorageService.getStoreCode();
    ApiService.ThirdParty.list(storeCode, accessToken)
      .then(res => {
        if (res.data === '沒有第三方串接資料') resolve({ lineChannelSecret: '', lineAccessToken: '', lineLiffId: '' });
        resolve({
          lineChannelSecret: res.data.lineChannelSecret ? res.data.lineChannelSecret : '',
          lineAccessToken: res.data.lineAccessToken ? res.data.lineAccessToken : '',
          lineLiffId: res.data.lineLiffId ? res.data.lineLiffId : '',
        });
      })
      .catch(err => {
        console.log('err', JSON.stringify(err));
        reject(err);
      });
  });
export default function StoreAccountDataTable() {
  const dispatch = useDispatch();
  const stores = useSelector(state => state.storeReducer);
  const [isTPDialogOpen, setIsTPDialogOpen] = React.useState(false); //TP: Third Party
  const [isGBDialogOpen, setIsGBDialogOpen] = React.useState(false); //GB: Game Belong
  const [rowData, setRowData] = React.useState({});
  const history = useHistory();

  useEffect(() => {
    dispatch(retrieveStores());
  }, [dispatch]);

  const updateContent = (id, data) => {
    dispatch(updateStore(id, data))
      .then(response => {
        console.log(response);
      })
      .catch(e => {
        console.log(e);
      });
  };

  // const deleteContent = id => {
  //   dispatch(deleteStore(id))
  //     .then(() => {
  //       //props.history.push("/storeAdmin");
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  const classes = useStyles();
  const [state, setState] = React.useState({
    columns: [
      { title: 'No.', render: rowData => rowData.tableData.id + 1 },
      { title: '店家代碼', field: 'storeCode' },
      { title: '店家名稱', field: 'storeName' },
      // { title: '店家電話', field: 'storePhone' },
      { title: '啟用狀態', field: 'storeEnabled', type: 'boolean' },
      // { title: '店家地址', field: 'storeAddr' },
    ],
    data: [],
  });

  const openTPPopup = rowData => {
    setRowData(rowData);
    setIsTPDialogOpen(true);
  };
  const openGBPopup = rowData => {
    setRowData(rowData);
    setIsGBDialogOpen(true);
  };

  const actions = [
    {
      icon: tableIcons.ThirdPartySetting,
      tooltip: '新增/異動第三方資料串接',
      onClick: (event, rowData) => {
        getThirdPartyData(rowData.storeCode).then(data => {
          openTPPopup({ ...rowData, ...data });
        });
      },
    },
    {
      icon: tableIcons.StoreFrontSetting,
      tooltip: '新增/異動商家遊戲、專案歸屬',
      onClick: (event, rowData) => {
        // console.log('rowData store Front', rowData)
        openGBPopup(rowData);
      },
    },
    {
      icon: () => <Store />,
      tooltip: '異動店家基本資料',
      onClick: (event, rowData) => {
        history.push(`/store/create?i=${rowData.id}&n=${rowData.storeName}`);
      },
    },
    {
      icon: () => <PersonAdd />,
      tooltip: '新增/異動店家管理者',
      onClick: (event, rowData) => {
        history.push(`/store/admin/create?i=${rowData.id}&n=${rowData.storeName}&sai=${rowData.storeFirstAdminId}`);
      },
    },
  ];

  return (
    <div>
      <MaterialTable
        className={classes.root}
        icons={tableIcons}
        title="店家資料列表"
        columns={state.columns}
        data={stores}
        options={{
          actionsColumnIndex: -1,
        }}
        actions={actions}
        editable={
          {
            //   // onRowAdd: newData =>
            //   //   new Promise(resolve => {
            //   //     setTimeout(() => {
            //   //       resolve();
            //   //       setState(prevState => {
            //   //         const data = [...prevState.data];
            //   //         data.push(newData);
            //   //         return { ...prevState, data };
            //   //       });
            //   //     }, 600);
            //   //   }),
            //   onRowUpdate: (newData, oldData) =>
            //     new Promise(resolve => {
            //       setTimeout(() => {
            //         resolve(updateContent(oldData.id, newData));
            //         // if (oldData) {
            //         //   setState(prevState => {
            //         //     const data = [...prevState.data];
            //         //     data[data.indexOf(oldData)] = newData;
            //         //     return { ...prevState, data };
            //         //   });
            //         //   console.log("999");
            //         // }
            //       }, 600);
            //     }),
            // onRowDelete: oldData =>
            //   new Promise(resolve => {
            //     setTimeout(() => {
            //       resolve(deleteContent(oldData.id));
            //       //setState(prevState => {
            //       //  const data = [...prevState.data];
            //       //  data.splice(data.indexOf(oldData), 1);
            //       //  return { ...prevState, data };
            //       //});
            //     }, 600);
            //   }),
          }
        }
      />
      <ThirdPartyDialog
        open={isTPDialogOpen}
        setIsDialogOpen={setIsTPDialogOpen}
        rowData={rowData}
        setRowData={setRowData}
      />
      <GameBelongDialog
        open={isGBDialogOpen}
        setIsDialogOpen={setIsGBDialogOpen}
        rowData={rowData}
        setRowData={setRowData}
        updateContent={updateContent}
      />
    </div>
  );
}
