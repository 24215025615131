import http from '../http-common';

const getAllUser = storeCode => {
  return http.get(`/storeAdmin/${storeCode}`);
};

const get = id => {
  return http.get(`/storeAdmin/${id}`);
};

const create = (storeCode, data) => {
  return http.post('/storeAdmin/' + storeCode, data);
};

// 建立新的(首位)店家管理員
const createStoreFirstAdmin = data => {
  return http.post('/storeFirstAdmin', data);
};

// 建立新的(首位)店家管理員
const updateStoreFirstAdmin = data => {
  return http.put('/storeFirstAdmin', data);
};

// 指定storeId查詢(首位)店家管理員
const findStoreFirstAdmin = id => {
  return http.get(`/storeFirstAdmin/store/${id}`);
};

const update = (id, data) => {
  return http.put(`/storeAdmin/${id}`, data);
};

const remove = id => {
  return http.delete(`/storeAdmin/${id}`);
};

const removeAll = () => {
  return http.delete(`/storeAdmin`);
};

const findByAdminAccount = adminAccount => {
  return http.get(`/storeAdmin?adminAccount=${adminAccount}`);
};

const findAllByPage = (page, size) => {
  return http.get(`/storeAdmin?page=${page}&size=${size}`);
};

const StoreAdminService = {
  getAllUser,
  get,
  create,
  createStoreFirstAdmin,
  updateStoreFirstAdmin,
  findStoreFirstAdmin,
  update,
  remove,
  removeAll,
  findByAdminAccount,
  findAllByPage,
};

export default StoreAdminService;
