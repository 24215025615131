import http from '../http-common';

const Product = {
  list: (storeCode, cateCode) => {
    return http.post('/v1/product/list/' + storeCode, {
      cateCode: cateCode,
    });
  },
  add: (storeCode, productData, accessToken) => {
    return http.post('/product/' + storeCode, productData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, productData, accessToken) => {
    return http.put('/product/' + storeCode, productData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, prodCode, accessToken) => {
    return http.post(
      '/product/remove/' + storeCode,
      {
        prodCode: prodCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Category = {
  list: storeCode => {
    return http.post('/category/list/' + storeCode);
  },
  add: (storeCode, cateData, accessToken) => {
    return http.post('/category/' + storeCode, cateData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, cateData, accessToken) => {
    return http.put('/category/' + storeCode, cateData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, cateCode, accessToken) => {
    return http.post(
      '/category/remove/' + storeCode,
      {
        cateCode: cateCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Activity = {
  list: storeCode => {
    return http.post('/activity/list/' + storeCode);
  },
  add: (storeCode, activityData, accessToken) => {
    return http.post('/activity/' + storeCode, activityData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, activityData, accessToken) => {
    return http.put('/activity/' + storeCode, activityData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, actCode, accessToken) => {
    return http.post(
      '/activity/remove/' + storeCode,
      {
        actCode: actCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};
const Banner = {
  list: storeCode => {
    return http.post('/v1/activity/list/' + storeCode);
  },
  add: (storeCode, bannerData, accessToken) => {
    return http.post('/banner/' + storeCode, bannerData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, bannerData, accessToken) => {
    return http.put('/banner/' + storeCode, bannerData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, actCode, accessToken) => {
    return http.post(
      '/banner/remove/' + storeCode,
      {
        actCode: actCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Pointcard = {
  list: storeCode => {
    return http.post('/pointcard/list/' + storeCode);
  },
  add: (storeCode, pcData, accessToken) => {
    return http.post('/pointcard/add/' + storeCode, pcData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, pcData, accessToken) => {
    return http.put('/pointcard/' + storeCode, pcData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, pcCode, accessToken) => {
    return http.post(
      '/pointcard/remove/' + storeCode,
      {
        pcCode: pcCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Coupon = {
  list: storeCode => {
    return http.post('/coupon/list/' + storeCode);
  },
  listForGroup: storeCode => {
    return http.post('/coupon/list/forgroup/' + storeCode);
  },
  usageList: (storeCode, accessToken) => {
    return http.post(
      '/coupon/usage/' + storeCode,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  add: (storeCode, cpnCode, accessToken) => {
    return http.post('/coupon/add/' + storeCode, cpnCode, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, cpnCode, accessToken) => {
    return http.put('/coupon/' + storeCode, cpnCode, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, cpnCode, accessToken) => {
    return http.post(
      '/coupon/remove/' + storeCode,
      {
        cpnCode: cpnCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const CouponGroup = {
  list: storeCode => {
    return http.post('/coupon/group/list/' + storeCode);
  },
  add: (storeCode, cpgCode, accessToken) => {
    return http.post('/coupon/group/add/' + storeCode, cpgCode, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, cpgCode, accessToken) => {
    return http.put('/coupon/group/modify/' + storeCode, cpgCode, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, cpgCode, accessToken) => {
    return http.post(
      '/coupon/group/remove/' + storeCode,
      {
        cpgCode: cpgCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Image = {
  upload: (storeCode, folder, file) => {
    let formData = new FormData();
    formData.append('file', file);
    return http.post(`/store/${storeCode}/upload/${folder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getFiles: () => {
    return http.get('/files');
  },
  download: (storeCode, accessToken) => {
    return http.post(
      `/store/${storeCode}/download/storeImage`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
        responseType: 'arraybuffer',
      },
    );
  },
};

const Order = {
  list: storeCode => {
    return http.post('/order/history/list/' + storeCode);
  },
};

const log = {
  add: (storeCode, logData, accessToken) => {
    return http.post(`/log/${storeCode}`, logData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  download: (storeCode, accessToken) => {
    return http.post(
      `/store/${storeCode}/download/log`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  listFrontend: (storeCode, accessToken) => {
    return http.post(
      `/log/frontend/parser/${storeCode}`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  listCms: (storeCode, accessToken) => {
    return http.post(
      `/log/cms/parser/${storeCode}`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  listFrontendAllStore: accessToken => {
    return http.get(
      `/log/frontend/parser/all`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  listCmsAllStore: accessToken => {
    return http.get(
      `/log/cms/parser/all`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const store = {
  download: (storeCode, accessToken) => {
    return http.post(
      `/store/${storeCode}/download/storeData`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
        responseType: 'arraybuffer',
      },
    );
  },
};

const Message = {
  list: (storeCode, accessToken) => {
    return http.get(
      '/message/list/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  add: (storeCode, accessToken, messageData) => {
    return http.post('/message/' + storeCode, messageData, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  quota: (storeCode, accessToken) => {
    return http.get(
      '/message/quota/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const ThirdParty = {
  list: (storeCode, accessToken) => {
    return http.get(
      '/thirdparty/list/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  add: (storeCode, accessToken, thirdpartyData) => {
    return http.post('/thirdparty/' + storeCode, thirdpartyData, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, accessToken, thirdpartyData) => {
    return http.put('/thirdparty/' + storeCode, thirdpartyData, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
};

const Game = {
  records: async (storeCode, accessToken) => {
    const response = await http.get(
      '/game/records/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Brear ' + accessToken,
        },
      },
    );
    log.add(storeCode, {
      requestUrl: '/game/' + storeCode,
      requestMethod: 'POST',
      responseStatus: response.status,
    });
    return response;
  },
  recordsAll: accessToken => {
    const response = http.get(
      '/game/all/records/',
      {},
      {
        headers: {
          Authorization: 'Brear ' + accessToken,
        },
      },
    );
    return response;
  },
};

const Tag = {
  list: async (storeCode, accessToken) => {
    const response = await http.get(
      '/store/members/tag/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Brear ' + accessToken,
        },
      },
    );
    return response;
  },
  add: async (storeCode, accessToken, tagData) => {
    const response = await http.post('/store/members/tag/' + storeCode, tagData, {
      headers: {
        Authorization: 'Brear ' + accessToken,
      },
    });
    return response;
  },
  modify: async (storeCode, accessToken, tagData) => {
    const response = await http.put('/store/members/tag/' + storeCode, tagData, {
      headers: {
        Authorization: 'Brear ' + accessToken,
      },
    });
  },
};

export default {
  Product,
  Image,
  Category,
  Activity,
  Banner,
  Pointcard,
  Coupon,
  CouponGroup,
  log,
  Order,
  store,
  Message,
  ThirdParty,
  Game,
  Tag,
};
