import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import localStorageService from 'services/LocalStorageService';
import QRCode from 'react-qr-code';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QrcodeDialog(props) {
  const { open, setIsDialogQrcodeOpen, children, rowData, initialValues, getPointcardList, coupon, ...others } = props;
  const [data, setData] = React.useState(initialValues);

  useEffect(() => {
    if (rowData.id !== undefined) {
      //console.log('rowData', rowData);
      setData({
        ...rowData,
      });
    }
  }, [rowData]);
  const handleClose = () => {
    setIsDialogQrcodeOpen(false);
  };
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>集點卡1點</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText />
          <Formik initialValues={data}>
            {props => {
              const { values } = props;
              // QRCode內容
              var qrdata = {
                storeCode: localStorageService.getStoreCode(),
                pcCode: values.pcCode,
                gotMemo: '',
              };
              return (
                <form>
                  <Grid container spacing={3}>
                    <Grid>
                      <div
                        style={{
                          height: 'auto',
                          margin: '0 auto',
                          maxWidth: 256,
                          width: '100%',
                        }}>
                        <QRCode
                          size={256}
                          style={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: '100%',
                          }}
                          value={JSON.stringify(qrdata)}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                      <div>
                        <p />
                      </div>
                      <div>
                        <p />
                      </div>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <div>{values.pcTitle}</div>
                    <Button type="button" color="primary" onClick={() => window.print()}>
                      列印
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
