import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import localStorageService from 'services/LocalStorageService';
import ApiService from 'services/apiService';
// import DataTable from './DataTable';
// import FormDialog from './FormDialog';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];

export default function Table() {
  const classes = useStyles();
  const [imageDownloading, setImageDownloading] = useState(false);
  const [storeDataDownloading, setStoreDataDownloading] = useState(false);
  const downloadLog = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();

    ApiService.log
      .download(storeCode, token)
      .then(res => {
        if (res.status === 200) {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          const fileName = 'log.txt';
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('target', '_blank');
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        }
      })
      .catch(err => console.log('errr', err));
  };
  const downloadStoreImage = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    setImageDownloading(true);

    ApiService.Image.download(storeCode, token)
      .then(res => {
        if (res.status === 200) {
          const fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/zip' }));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          const fileName = `${storeCode}-storeImage.zip`;
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('target', '_blank');
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
          setImageDownloading(false);
        }
      })
      .catch(err => console.log('errr', err));
  };
  const downloadStoreData = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    setStoreDataDownloading(true);

    ApiService.store
      .download(storeCode, token)
      .then(res => {
        if (res.status === 200) {
          const fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/zip' }));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          const fileName = `${storeCode}-storeData.zip`;
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('target', '_blank');
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
          setStoreDataDownloading(false);
        }
      })
      .catch(err => console.log('errr', err));
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.member.download" />} breadcrumbs={breadcrumbs}>
      <Box
        className={classes.section}
        sx={{
          backgroundColor: '#FFF',
          margin: 'auto',
          padding: '10px',
          boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
          borderRadius: '5px',
        }}>
        <div className="">
          <div className="row">
            <div className="col-12 mt-2">
              {/* <button className="btn btn-outline-primary">下載完整資料</button> */}
              <a
                className="btn btn-outline-primary"
                target="_blank"
                rel="noopener noreferrer"
                // href="https://adm-doc.membercard.cc/membercard.zip"
                onClick={downloadStoreData}
                role="button">
                {storeDataDownloading ? '下載中' : '下載完整資料'}
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              {/* <button className="btn btn-outline-primary">下載完整圖檔</button> */}
              <a
                className="btn btn-outline-primary"
                target="_blank"
                rel="noopener noreferrer"
                // href="https://adm-doc.membercard.cc/picture.zip"
                onClick={downloadStoreImage}
                role="button">
                {imageDownloading ? '下載中' : '下載完整圖檔'}
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              {/* <button className="btn btn-outline-primary">下載使用記錄檔</button> */}
              <a
                className="btn btn-outline-primary"
                target="_blank"
                rel="noopener noreferrer"
                // href="https://adm-doc.membercard.cc/log.zip"
                onClick={downloadLog}
                role="button">
                下載使用記錄檔
              </a>
            </div>
          </div>
        </div>
      </Box>
      {/* <Box className={classes.section}>
        <DataTable />
      </Box>
      <Box className={classes.section}>
        <FormDialog />
      </Box> */}
    </PageContainer>
  );
}
