import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import './../../../../assets/css/signin.css';
// import DataTable from './DataTable';
// import FormDialog from './FormDialog';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];

export default function Table() {
  const classes = useStyles();
  return (
    <div className="container" style={{ paddingTop: '50px' }}>
      <div className="singin-form">
        <div className="row">
          <h1>Signin</h1>
        </div>
        <div className="row align-items-stretch mb-2">
          <div className="col-md-12">
            <input
              className="form-control"
              id="adminAccount"
              type="text"
              placeholder="請輸入店管帳號"
              data-sb-validations="required"
              name="adminAccount"
              // onChange={this.props.onChange}
              // onBlur={this.props.onBlur}
              // value={this.props.value}
              // onChange={e => this.props.onChange(e)}
            />
          </div>
        </div>
        <div className="row align-items-stretch mb-2">
          <div className="col-md-12">
            <input
              className="form-control"
              id="adminAccount"
              type="text"
              placeholder="請輸入店管密碼"
              data-sb-validations="required"
              name="adminAccount"
              // onChange={this.props.onChange}
              // onBlur={this.props.onBlur}
              // value={this.props.value}
              // onChange={e => this.props.onChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <form>
            <div className="col-12 mt-2">
              <button className="btn btn-primary mr-2">登入</button>
              <button className="btn btn-outline-secondary">重設</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
