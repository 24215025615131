/**
 * 使用Material UI 創建介面
 * 使用Formik 進行表單處理和驗證
 */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog(props) {
  // console.log('Form Dialog rendering');
  const { open, setIsDialogOpen, children, rowData, getMemberList, getStoreTags, ...others } = props;
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    // console.log('fn/ isAccessTokenValid', validToken.isValid)
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    setIsDialogOpen(false);
    rowData.map(mbr => {
      const tag = mbr.mbrTag ? JSON.parse(mbr.mbrTag) : [];
      tag.push(values.tagName);
      ApiService.Tag.add(storeCode, token, {
        memberId: mbr.id,
        tag: tag,
      })
        .then(res => {
          getMemberList();
          getStoreTags();
        })
        .catch(err => console.log('reject ', err));
    });
    // console.log('rowData', rowData)
    // console.log('values', values.tagName)
  };

  const validationSchema = yup.object().shape({
    tagName: yup.string('tag Name').required('必填欄位'),
  });
  return (
    <Box>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth="true">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <h2>新增標籤</h2>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3} xs="auto">
            <Grid item xs="4">
              <DialogContentText>已選取會員</DialogContentText>
              {rowData.map(mbr => {
                return <p>{mbr.mbrNick}</p>;
              })}
            </Grid>
            <Grid item xs="8">
              <DialogContentText>請填入下列資訊</DialogContentText>
              <Formik initialValues={rowData} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
                {props => {
                  const { values, touched, errors, dirty, isSubmitting, handleChange, handleSubmit, handleReset } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        required
                        as={TextField}
                        autoFocus
                        margin="dense"
                        id="tagName"
                        name="tagName"
                        label="標籤名稱"
                        type="text"
                        fullWidth
                        value={values.tagName}
                        onChange={handleChange}
                        error={touched.tagName && Boolean(errors.tagName)}
                        helperText={touched.tagName && errors.tagName}
                      />
                      <DialogActions>
                        <Button
                          type="button"
                          className="outline"
                          color="primary"
                          onClick={handleReset}
                          disabled={!dirty || isSubmitting}>
                          清除
                        </Button>
                        <Button type="submit" color="primary" disabled={isSubmitting}>
                          確定新增
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
