const newNameCode = (prefix, nameCodeDB) => {
  if (nameCodeDB.length === 0) return prefix + '0001';
  const digits = [];
  nameCodeDB.map(item => {
    const [word, digit] = item.split(/(?<=\D)(?=\d)/);
    digits.push(parseInt(digit));
  });
  digits.sort((a, b) => b - a);
  let Num = digits[0] + 1;
  while (Num.toString().length < 4) Num = '0' + Num;
  return prefix + Num;
};

export default newNameCode;
