/**
 * 使用Material UI 創建介面
 * 使用Formik 進行表單處理和驗證
 */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { DateTimePicker } from '@material-ui/pickers';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog({ getPointcardList, initialValues, coupon }) {
  // console.log('Form Dialog rendering');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    setOpen(false);
    const pcData = {
      pcCode: values.pcCode,
      pcTitle: values.pcTitle,
      pcSubTitle: values.pcSubTitle,
      pcDesc: values.pcDesc,
      pcStartTime: values.pcStartTime,
      pcStopTime: values.pcStopTime,
      pcMaxPoint: values.pcMaxPoint,
      pcGotPoint: values.pcGotPoint,
      pcRepeatable: values.pcRepeatable,
      couponId: values.couponId == 0 ? null : values.couponId,
    };
    // console.log('Point Card data', pcData);
    ApiService.Pointcard.add(storeCode, pcData, token).then(async response => {
      ApiService.log.add(
        storeCode,
        {
          requestUrl: `/pointcard/add/${storeCode}`,
          requestMethod: 'POST',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        await getPointcardList();
      }
    });
  };

  const validationSchema = yup.object().shape({
    pcCode: yup.string('Point Card Code').required('必填欄位'),
    pcTitle: yup.string('Point Card Name').required('必填欄位'),
  });
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>新增集點卡</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>請填入下列資訊</DialogContentText>
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs="6">
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="pcCode"
                        name="pcCode"
                        label="集點卡代碼"
                        type="text"
                        fullWidth
                        disabled
                        value={values.pcCode}
                        onChange={handleChange}
                        error={touched.pcCode && Boolean(errors.pcCode)}
                        helperText={touched.pcCode && errors.pcCode}
                      />
                      <Field
                        required
                        autoFocus
                        as={TextField}
                        margin="dense"
                        id="pcTitle"
                        name="pcTitle"
                        label="集點卡標題"
                        type="text"
                        fullWidth
                        value={values.pcTitle}
                        onChange={handleChange}
                        error={touched.pcTitle && Boolean(errors.pcTitle)}
                        helperText={touched.pcTitle && errors.pcTitle}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="pcSubTitle"
                        name="pcSubTitle"
                        label="集點卡副標題"
                        type="text"
                        fullWidth
                        multiline
                        value={values.pcSubTitle}
                        onChange={handleChange}
                        error={touched.pcSubTitle && Boolean(errors.pcSubTitle)}
                        helperText={touched.pcSubTitle && errors.pcSubTitle}
                      />
                      <Field
                        as={TextField}
                        defaultValue="Point Card Description defaut content."
                        margin="dense"
                        id="pcDesc"
                        name="pcDesc"
                        label="集點卡說明"
                        type="text"
                        fullWidth
                        multiline
                        value={values.pcDesc}
                        onChange={handleChange}
                        error={touched.pcDesc && Boolean(errors.pcDesc)}
                        helperText={touched.pcDesc && errors.pcDesc}
                      />
                      <Field required as={FormControl} margin="dense" fullWidth>
                        <InputLabel id="coupon">集滿優惠券</InputLabel>
                        <Select
                          labelId="coupon"
                          id="couponId"
                          name="couponId"
                          value={values.couponId}
                          label="集滿優惠券"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          onChange={e => {
                            setFieldValue('couponId', e.target.value);
                          }}>
                          {<MenuItem value={0}>不使用優惠券</MenuItem>}
                          {coupon.map(item => {
                            return <MenuItem value={item.id}>{item.cpnName}</MenuItem>;
                          })}
                        </Select>
                      </Field>
                    </Grid>
                    <Grid item xs="6">
                      <DateTimePicker
                        margin="dense"
                        id="pcStartTime"
                        name="pcStartTime"
                        label="集點卡開始時間"
                        value={values.pcStartTime}
                        onChange={val => {
                          // console.log('picked pointcard start time', val.format());
                          setFieldValue('pcStartTime', val);
                        }}
                        renderInput={params => <TextField {...params} />}
                      />
                      <DateTimePicker
                        margin="dense"
                        id="pcStopTime"
                        name="pcStopTime"
                        label="集點卡結束時間"
                        value={values.pcStopTime}
                        onChange={val => {
                          // console.log('picked pointcard stop time', val.format());
                          setFieldValue('pcStopTime', val);
                        }}
                        renderInput={params => <TextField {...params} />}
                      />
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="pcMaxPoint"
                        name="pcMaxPoint"
                        label="每張卡點數"
                        defaultValue="10"
                        fullWidth
                        value={values.pcMaxPoint}
                        onChange={handleChange}
                        error={touched.pcMaxPoint && Boolean(errors.pcMaxPoint)}
                        helperText={touched.pcMaxPoint && errors.pcMaxPoint}
                      />
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="pcGotPoint"
                        name="pcGotPoint"
                        label="一次點數"
                        defaultValue="1"
                        fullWidth
                        value={values.pcGotPoint}
                        onChange={handleChange}
                        error={touched.pcGotPoint && Boolean(errors.pcGotPoint)}
                        helperText={touched.pcGotPoint && errors.pcGotPoint}
                      />
                      <Field
                        as={FormControlLabel}
                        margin="dense"
                        control={<Checkbox id="pcRepeatable" name="pcRepeatable" />}
                        label="集滿重複集點"
                        checked={values.pcRepeatable}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定新增
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add New
      </Button>
    </Box>
  );
}
