import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import Button from '@material-ui/core/Button';
import RawDataTable from './RawDataTable';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

// 各參數初始值
const initialLogData = {
  store: { data: ['', 0] },
  activity: { data: ['', 0] },
  product: { data: ['', 0] },
  pointcard: { data: ['', 0] },
  coupon: { data: ['', 0] },
  member: { data: ['', 0] },
  cart: { data: ['', 0] },
  category: { data: ['', 0] },
  game: { data: ['', 0] },
};
const initialLabelData = [''];
const initialHashTable = { 12345678: '' };
const initialFilterDate = {
  start: `${new Date().getFullYear()}-${(new Date().getMonth() - 2).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
  end: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
};
const initialRawData = [];
const initialStoreCode = '00000000';

/**函式區 */

// 取得個別商家log 函式
const getAnalysisLog = storeCode =>
  new Promise((resolve, reject) => {
    const token = localStorageService.getToken();
    ApiService.log
      .listCms(storeCode, token)
      .then(response => {
        const result = {};
        const rawData = JSON.parse(JSON.stringify(response.data.logData));

        rawData.store.data = Object.entries(rawData.store.data);
        rawData.activity.data = Object.entries(rawData.activity.data);
        rawData.product.data = Object.entries(rawData.product.data);
        rawData.pointcard.data = Object.entries(rawData.pointcard.data);
        rawData.coupon.data = Object.entries(rawData.coupon.data);
        rawData.member.data = Object.entries(rawData.member.data);
        rawData.cart.data = Object.entries(rawData.cart.data);
        rawData.category.data = Object.entries(rawData.category.data);
        rawData.game.data = Object.entries(rawData.game.data);
        delete rawData.labels;

        result.labels = response.data.logData.labels;
        result.logData = rawData;
        result.rawData = response.data.rawData;
        resolve(result);
      })
      .catch(err => reject(err));
  });
/** hash 函式
 * x 軸labels(時間)轉為timestamp的hash table
 * @param {Array} labels
 * @returns
 */
const hashfn = labels => {
  let hashTable = {};
  labels.map(label => {
    hashTable[new Date(label).setHours(0, 0, 0, 0)] = label;
  });
  return hashTable;
};
// x軸label過濾(時間)
const labelFilter = (labels, startTime, endTime) =>
  new Promise((resolve, reject) => {
    // x 時間軸filter
    let filteredDateLabel = labels
      .map(label => new Date(label).setHours(0, 0, 0, 0))
      .filter(item => item >= startTime && item <= endTime);
    resolve(filteredDateLabel);
  });
// 資料隨著時間過濾
const dataFilter = (logData, startTime, endTime) =>
  new Promise((resolve, reject) => {
    // data filter 暴力過濾法 (待優化)
    const filteredData = Object.entries(logData).map(item => {
      // 此層結構 => ['store',{...}]
      const result = Object.entries(item[1]).map(dataItem => {
        // 此層結構 => [['21/Oct/2022', 8],['22/Oct/2022', 6]]
        dataItem[1] = dataItem[1].filter(item => {
          // 此層結構 => ['21/Oct/2022', 8]
          const timestamp = new Date(item[0]).setHours(0, 0, 0, 0);
          return timestamp >= startTime && timestamp <= endTime;
        });
        return dataItem;
      });
      item[1] = Object.fromEntries(result);
      return item;
    });
    resolve(Object.fromEntries(filteredData));
  });

// chart.js 設定config
const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '管理後臺點擊曲線圖',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
export default function CmsDataByStore() {
  const classes = useStyles();
  const [labels, setLebels] = React.useState(initialLabelData);
  const [logData, setLogData] = React.useState(initialLogData);
  const [rawData, setRawData] = React.useState(initialRawData);
  const [filteredLabel, setFilteredLabel] = React.useState(initialLabelData);
  const [filteredLogdata, setFilteredLogData] = React.useState(initialLogData);
  const [filteredRawData, setFilteredRawData] = React.useState(initialRawData);
  const [hashTable, setHashTable] = React.useState(initialHashTable);

  const data = {
    labels: filteredLabel,
    datasets: [
      {
        label: '商家資訊',
        data: filteredLogdata.store.data,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: '活動資訊',
        data: filteredLogdata.activity.data,
        borderColor: 'rgba(255, 159, 64)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
      {
        label: '商品管理',
        data: filteredLogdata.product.data,
        borderColor: 'rgba(255, 205, 86)',
        backgroundColor: 'rgba(255, 205, 86, 0.2)',
      },
      {
        label: '集點卡管理',
        data: filteredLogdata.pointcard.data,
        borderColor: 'rgba(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: '優惠管理',
        data: filteredLogdata.coupon.data,
        borderColor: 'rgba(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: '會員管理',
        data: filteredLogdata.member.data,
        borderColor: 'rgba(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
      },
      {
        label: '訂單紀錄',
        data: filteredLogdata.cart.data,
        borderColor: 'rgba(201, 203, 207)',
        backgroundColor: 'rgba(201, 203, 207, 0.2)',
      },
      {
        label: '商品分類管理',
        data: filteredLogdata.category.data,
        borderColor: 'rgb(128, 155, 0)',
        backgroundColor: 'rgb(128, 155, 0, 0.2)',
      },
      {
        label: 'AR遊戲管理',
        data: filteredLogdata.game.data,
        borderColor: 'rgb(128, 155, 0)',
        backgroundColor: 'rgb(128, 155, 0, 0.2)',
      },
    ],
  };
  // 日期和統編輸入
  const [filterDate, setFilterDate] = React.useState(initialFilterDate);
  const [storeCode, setStoreCode] = React.useState(initialStoreCode);
  const handleStartChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, start: event.target.value }));
  };
  const handleEndChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, end: event.target.value }));
  };
  const handleStoreCodeChange = event => {
    event.persist();
    setStoreCode(event.target.value);
  };

  // 店家搜尋
  const handleClickStoreCode = event => {
    // 若storeCode為預設值 00000000 直接返回不做任何動作
    if (storeCode === '00000000') return;
    event.preventDefault();
    getAnalysisLog(storeCode)
      .then(result => {
        setLebels(result.labels);
        setFilteredLabel(result.labels);
        setLogData(result.logData);
        setFilteredLogData(result.logData);
        setRawData(result.rawData);
        setFilteredRawData(result.rawData);
        const labelHashTable = hashfn(result.labels);
        setHashTable(labelHashTable);
      })
      .catch(error => {
        // 出現錯誤回復初始設定值
        setFilteredLabel(initialLabelData);
        setFilteredLogData(initialLogData);
        setFilteredRawData(initialRawData);
        setRawData(initialRawData);
        setLebels(initialLabelData);
        setLogData(initialLogData);
        setFilterDate(initialFilterDate);
      });
  };
  // 過濾搜尋
  const handleClick = event => {
    event.preventDefault();
    const startTime = new Date(filterDate.start).setHours(0, 0, 0, 0);
    const endTime = new Date(filterDate.end).setHours(0, 0, 0, 0);
    labelFilter(labels, startTime, endTime).then(res => {
      const filteredDateLabel = res.map(item => hashTable[item]);
      setFilteredLabel(filteredDateLabel);
    });
    dataFilter(logData, startTime, endTime).then(res => setFilteredLogData(res));
  };
  // 清除
  const handleClickReset = event => {
    event.preventDefault();
    setFilteredLabel(initialLabelData);
    setFilteredLogData(initialLogData);
    setFilteredRawData(initialRawData);
    setRawData(initialRawData);
    setLebels(initialLabelData);
    setLogData(initialLogData);
    setFilterDate(initialFilterDate);
    setStoreCode(initialStoreCode);
  };
  return (
    <div>
      <Box className={classes.section} style={{ height: '400px', paddingBottom: '150px' }}>
        <h3>後臺操作-個別店家</h3>
        <p>
          開始時間: {filterDate.start} 結束時間: {filterDate.end}
        </p>
        <input
          type="text"
          id="storeCode"
          name="storeCode"
          onChange={handleStoreCodeChange}
          value={storeCode === '00000000' ? '' : storeCode}
          placeholder="商家統編"
        />
        <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClickStoreCode}>
          店家搜尋
        </Button>
        <br />
        <input type="date" id="start" name="start" onChange={handleStartChange} value={filterDate.start} />
        <input type="date" id="end" name="end" onChange={handleEndChange} value={filterDate.end} />

        <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClick}>
          過濾
        </Button>
        <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClickReset}>
          清除
        </Button>
        <Line data={data} width={600} height={400} options={options} />
      </Box>
      <RawDataTable rawData={filteredRawData} />
    </div>
  );
}
