// 1.
import React, { forwardRef } from 'react';
import { Box, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { Formik, Field } from 'formik';
import StoreAdminService from 'services/StoreService';
import LocalStorageService from 'services/LocalStorageService';
import apiService from 'services/apiService';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '../../../../@jumbo/constants/TableIcons';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import twLocale from 'date-fns/locale/zh-TW';
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import MessageFormDialog from './MessageFormDialog';
import TagFormDialog from './TagFormDialog';
import EditTagFormDialog from './EditTagFormDialog';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { Add, Edit } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];

const tableIcons = {
  SendRoundedIcon: forwardRef((props, ref) => <SendRoundedIcon {...props} ref={ref} />),
  AddIcon: forwardRef((props, ref) => <Add {...props} ref={ref} />),
  EditIcon: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
};

export default function Table() {
  const [data, setData] = React.useState({
    mbrTagCode: [],
    LineLiff: true,
    registerFrom: new Date(),
    registerTo: new Date(),
  }); //呈現表格用
  const [quotaData, setQuotaData] = React.useState({});
  const [messageData, setMessageData] = React.useState({ msgCode: '', memberIds: [] });
  const classes = useStyles();
  const [state, setState] = React.useState({
    columns: [
      { title: 'No.', render: rowData => rowData.tableData.id + 1 },
      { title: '會員卡號', field: 'mbrCode' },
      { title: '會員姓名', field: 'mbrName' },
      { title: '會員暱稱', field: 'mbrNick' },
      {
        title: '會員標籤',
        field: 'mbrTag',
        render: rowData => {
          if (rowData.mbrTag === null) return;
          const Tags = JSON.parse(rowData.mbrTag);
          const tagsJSX = Tags.map(tag => (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              style={{
                borderRadius: '20px',
                margin: '2px',
                paddingRight: '10px',
              }}>
              {tag}
            </Button>
          ));
          return <>{tagsJSX}</>;
        },
      },
      {
        title: '會員手機號碼',
        render: rowData => (rowData.mbrMP ? rowData.mbrMP : rowData.mbrMobile.length === 10 ? rowData.mbrMobile : ''),
      },
      // { title: '電子郵件', field: 'mbrEmail' },
      { title: '啟用狀態', field: 'mbrEnabled', type: 'boolean' },
      { title: '會員來源', field: 'mbrFrom', render: rowData => (rowData.mbrFrom === 'web' ? '網頁會員' : 'LINE會員') },
      { title: '加入日期', field: 'createdAt', type: 'date' },
    ],
  });
  const [member, setMember] = React.useState([]);
  const [filteredMember, setFilteredMember] = React.useState([]);
  const [rowData, setRowData] = React.useState([]);
  const [editTagRowData, setEditTagRowData] = React.useState([]);
  const [functionOn, setFunctionOn] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [tagsFlag, setTagsFlag] = React.useState({});
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
  const [openTagDialog, setOpenTagDialog] = React.useState(false);
  const [openEditTagDialog, setOpenEditTagDialog] = React.useState(false);

  React.useEffect(() => {
    getMemberList();
    getStoreTags();
    functionIsOn();
  }, []);

  const initialValues = {
    subject: '訊息標題',
    messageBody: '訊息內容',
  };
  const getMemberList = () => {
    const storeCode = LocalStorageService.getStoreCode();
    const token = LocalStorageService.getToken();
    StoreAdminService.findAllMembersByStore(storeCode, token).then(response => {
      apiService.log.add(
        storeCode,
        {
          requestUrl: `/store/members/${storeCode}`,
          requestMethod: 'GET',
          responseStatus: response.status,
        },
        token,
      );
      setMember(response.data);
    });
  };
  const getStoreTags = () => {
    const storeCode = LocalStorageService.getStoreCode();
    const token = LocalStorageService.getToken();
    apiService.Tag.list(storeCode, token).then(res => {
      // console.log('res tag', res)
      setTags(res.data);
    });
  };
  // 檢查DB是否有第三方串接資料: 是->開啟推播功能
  const functionIsOn = () => {
    // 檢查是否有Line AccessToken和channel secret來開啟line message推播功能
    const storeCode = LocalStorageService.getStoreCode();
    const token = LocalStorageService.getToken();
    apiService.ThirdParty.list(storeCode, token).then(res => {
      if (res.data !== '沒有第三方串接資料') {
        setFunctionOn(true);
      } else {
        setFunctionOn(false);
      }
    });
  };
  // 篩選過濾
  const handleFilterOnSubmit = async values => {
    // 過濾註冊時間
    let mbrFilteredByTime = member.filter(mbr => {
      const createdAtTimestamp = Date.parse(mbr.createdAt);
      return (
        createdAtTimestamp >= new Date(values.registerFrom).setHours(0, 0, 0, 0) &&
        createdAtTimestamp <= new Date(values.registerTo).setHours(23, 59, 59, 999)
      );
    });
    // console.log('mbrFilteredByTime',mbrFilteredByTime)
    // 過濾是否Line會員
    let mbrFilteredByLine = mbrFilteredByTime.filter(mbr => {
      if (values.LineLiff === true) {
        return mbr.mbrFrom === 'LineLiff';
      } else {
        return mbr.mbrFrom !== 'LineLiff';
      }
    });
    // console.log('mbrFilteredByLine', mbrFilteredByLine)
    // 篩選flag為true的tag
    const tag = Object.fromEntries(Object.entries(tagsFlag).filter(([key, values]) => values === true));
    // 過濾會員標籤
    let mbrFilteredByTag = mbrFilteredByLine.filter(mbr => {
      let key = Object.keys(tag);
      if (key.length === 0) return true;
      for (let i = 0; i < key.length; i++) {
        return mbr.mbrTag !== null && mbr.mbrTag.includes(key[i]);
      }
    });
    // console.log('mbrFilteredByTag', mbrFilteredByTag)
    if (mbrFilteredByTag.length === 0) alert('未發現符合條件會員，請重新搜尋');
    setFilteredMember(mbrFilteredByTag);
  };

  // ******table 的Action按鈕****** //
  const handleMessageActionsOnClick = data => {
    // console.log('data', data);
    const messageData = {
      msgCode: '',
      memberIds: [],
    };
    const dateTime = new Date().toLocaleString('zh-TW', { hour12: false }).replace(/\D/g, '');
    messageData.msgCode = 'MSG' + dateTime;
    data.map(member => messageData.memberIds.push(member.mbrMobile));
    setMessageData(messageData);
    getQuota().then(async quota => {
      if (quota.message === 'success') {
        setQuotaData(quota);
      }
    });
    handleMessageClickOpen();
  };
  const handleTagActionsOnClick = data => {
    setRowData(data);
    handleTagClickOpen();
  };
  const handleEditTagOnClick = data => {
    setEditTagRowData(data[0]);
    handleEditTagClickOpen();
  };

  // 取得line當月剩餘推播次數
  const getQuota = async () => {
    const storeCode = LocalStorageService.getStoreCode();
    const token = LocalStorageService.getToken();
    const quota = {
      message: '',
    };
    await apiService.Message.quota(storeCode, token)
      .then(result => {
        if (result.status === 200) {
          // console.log(result.data);
          quota.message = 'success';
          quota.monthLimits = result.data.monthLimits;
          quota.totalUsage = result.data.totalUsage;
        }
      })
      .catch(err => console.log(err, 'Front End Error/ Some errors occurred while getting Quota.'));
    return quota;
  };

  // ******視窗開啟控制****** //
  // 推播訊息視窗開啟
  const handleMessageClickOpen = () => {
    setOpenMessageDialog(true);
  };
  // 推播訊息視窗關閉
  const handleMessageClose = () => {
    setOpenMessageDialog(false);
  };
  // 新增標籤視窗開啟
  const handleTagClickOpen = () => {
    setOpenTagDialog(true);
  };
  // 新增標籤視窗關閉
  const handleTagClose = () => {
    setOpenTagDialog(false);
  };
  // 編輯標籤視窗開啟
  const handleEditTagClickOpen = () => {
    setOpenEditTagDialog(true);
  };
  // 編輯標籤視窗關閉
  const handleEditTagClose = () => {
    setOpenEditTagDialog(false);
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.message.create" />} breadcrumbs={breadcrumbs}>
      {functionOn ? (
        <div>
          <Box
            className={classes.section}
            sx={{
              backgroundColor: '#FFF',
              // margin: 'auto',
              padding: '10px',
              boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
              borderRadius: '5px',
              width: 800,
            }}>
            <Typography variant="h2" align="center">
              篩選
            </Typography>
            <Formik initialValues={data} onSubmit={handleFilterOnSubmit}>
              {props => {
                const { values, dirty, setFieldValue, isSubmitting, handleChange, handleSubmit, handleReset } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6} xs={20}>
                      <Grid item xs={6}>
                        <InputLabel>標籤</InputLabel>
                        <Button
                          color="primary"
                          variant={tagsFlag['有消費紀錄'] ? 'contained' : 'outlined'}
                          style={{
                            borderRadius: '20px',
                            margin: '2px',
                          }}
                          onClick={() => {
                            setTagsFlag(prevState => ({ ...prevState, 有消費紀錄: !prevState['有消費紀錄'] }));
                          }}>
                          有消費紀錄
                        </Button>
                        <Button
                          color="primary"
                          variant={tagsFlag['有購物意願'] ? 'contained' : 'outlined'}
                          style={{
                            borderRadius: '20px',
                            margin: '2px',
                          }}
                          onClick={() => {
                            setTagsFlag(prevState => ({ ...prevState, 有購物意願: !prevState['有購物意願'] }));
                          }}>
                          有購物意願
                        </Button>
                        {tags.map(tag => {
                          // const name = item.mbrTagName
                          if (tag.length !== 0)
                            return (
                              <Button
                                color="primary"
                                variant={tagsFlag[tag] ? 'contained' : 'outlined'}
                                style={{
                                  borderRadius: '20px',
                                  margin: '2px',
                                }}
                                onClick={() => {
                                  setTagsFlag(prevState => {
                                    const state = prevState;
                                    state[tag] = !state[tag];
                                    return { ...state };
                                  });
                                }}>
                                {tag}
                              </Button>
                            );
                        })}
                      </Grid>
                      <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={twLocale}>
                          <DatePicker
                            margin="dense"
                            id="registerFrom"
                            name="registerFrom"
                            label="開始時間"
                            value={values.registerFrom}
                            fullWidth
                            onChange={val => {
                              // console.log('picked activity start time', val.format());
                              setFieldValue('registerFrom', val);
                            }}
                            renderInput={params => <TextField {...params} />}
                          />
                          <DatePicker
                            margin="dense"
                            id="registerTo"
                            name="registerTo"
                            label="結束時間"
                            value={values.registerTo}
                            fullWidth
                            onChange={val => {
                              // console.log('picked activity start time', val.format());
                              setFieldValue('registerTo', val);
                            }}
                            renderInput={params => <TextField {...params} />}
                          />
                        </MuiPickersUtilsProvider>
                        <Field
                          as={FormControlLabel}
                          control={<Checkbox id="LineLiff" name="LineLiff" />}
                          label="Line會員"
                          fullWidth
                          checked={values.LineLiff}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      fullWidth="true"
                      onClick={() => {
                        handleReset();
                        setFilteredMember([]);
                      }}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      fullWidth="true"
                      size="large"
                      // disabled={isSubmitting}
                    >
                      搜尋
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </Box>
          <MaterialTable
            className={classes.root}
            icons={TABLE_ICONS}
            title="會員資料列表"
            columns={state.columns}
            data={filteredMember.length > 0 ? filteredMember : member}
            options={{
              actionsColumnIndex: -1,
              selection: true,
            }}
            actions={[
              {
                tooltip: '發送訊息',
                icon: tableIcons.SendRoundedIcon,
                onClick: (evt, data) => {
                  handleMessageActionsOnClick(data);
                },
              },
              {
                tooltip: '新增標籤',
                icon: tableIcons.AddIcon,
                onClick: (evt, data) => {
                  handleTagActionsOnClick(data);
                },
              },
              {
                tooltip: '編輯標籤',
                icon: tableIcons.EditIcon,
                onClick: (evt, data) => {
                  handleEditTagOnClick(data);
                },
              },
            ]}
          />
          <MessageFormDialog
            open={openMessageDialog}
            setOpen={setOpenMessageDialog}
            onClose={handleMessageClose}
            initialValues={initialValues}
            quotaData={quotaData}
            messageData={messageData}
          />
          <TagFormDialog
            open={openTagDialog}
            setIsDialogOpen={setOpenTagDialog}
            onClose={handleTagClose}
            rowData={rowData}
            getMemberList={getMemberList}
            getStoreTags={getStoreTags}
          />
          <EditTagFormDialog
            open={openEditTagDialog}
            setIsDialogOpen={setOpenEditTagDialog}
            onClose={handleEditTagClose}
            rowData={editTagRowData}
            getMemberList={getMemberList}
            getStoreTags={getStoreTags}
          />
        </div>
      ) : functionOn === undefined ? (
        ''
      ) : (
        <div>
          <h1>功能尚未啟用，請洽服務人員</h1>
        </div>
      )}
    </PageContainer>
  );
}
