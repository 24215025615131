import { CREATE_STORE, RETRIEVE_STORES, RETRIEVE_STORE, UPDATE_STORE } from '../../@jumbo/constants/ActionTypes';

import storeDataService from '../../services/StoreService';
import commonService from '../../services/CommonService';

export const createStore = storeData => async dispatch => {
  try {
    const res = await storeDataService.create(storeData);

    if (res.status === 200) {
      // window.alert(`新增店家「${storeData.storeName}」成功`);
      commonService.popupMsg(`新增店家「${storeData.storeName}」成功`);
    } else {
      // window.alert(`新增店家「${storeData.storeName}」失敗`);
      commonService.popupMsg(`新增店家「${storeData.storeName}」失敗`);
    }
    dispatch({
      type: CREATE_STORE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// export const createStore = (storeCode, storeName) => async dispatch => {
//   try {
//     const res = await storeDataService.create({
//       storeCode: storeCode,
//       storeName: storeName,
//     });

//     dispatch({
//       type: CREATE_STORE,
//       payload: res.data,
//     });

//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };

export const retrieveStores = () => async dispatch => {
  //page, size
  try {
    const res = await storeDataService.getAll(); //.findAllByPage(page, size); //
    console.log('store.retrieve', res);
    dispatch({
      type: RETRIEVE_STORES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const retrieveStore = storeId => async dispatch => {
  //page, size
  try {
    const res = await storeDataService.get(storeId); //.findAllByPage(page, size); //
    console.log('storeId', storeId, 'store.retrieve', res);
    dispatch({
      type: RETRIEVE_STORE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateStore = (id, storeData) => async dispatch => {
  try {
    await storeDataService.update(id, storeData);
    const res = await storeDataService.getAll();

    if (res.status === 200) {
      // window.alert(`更新店家「${storeData.storeName}」成功`);
      commonService.popupMsg(`更新店家「${storeData.storeName}」成功`);
    } else {
      // window.alert(`更新店家「${storeData.storeName}」失敗`);
      commonService.popupMsg(`更新店家「${storeData.storeName}」失敗`);
    }

    // const t = performance.now()
    dispatch({
      type: UPDATE_STORE,
      payload: res.data, //data,
    });
    // console.log('Time after dispatching', performance.now() - t)
    //return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    //return Promise.reject(err);
  }
};
// export const updateStoreAdminTest = (id, data) => async dispatch => {
//   await storeAdminDataService.update(id, data)
//   const res = await storeAdminDataService.getAll()
//   console.log('res:', res.data)
//   dispatch({
//     type: UPDATE_STORE_ADMIN,
//     payload:
//   })
// }

// export const deleteStoreAdmin = id => async dispatch => {
//   try {
//     await storeAdminDataService.remove(id);
//     const res = await storeAdminDataService.getAll();

//     dispatch({
//       type: DELETE_STORE_ADMIN,
//       payload: res.data, //{ id },
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const deleteAllStoreAdmins = () => async dispatch => {
//   try {
//     const res = await storeAdminDataService.removeAll();

//     dispatch({
//       type: DELETE_ALL_STORE_ADMINS,
//       payload: res.data,
//     });

//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };

// export const findStoreAdminsByAdminAccount = adminAccount => async dispatch => {
//   try {
//     const res = await storeAdminDataService.findByAdminAccount(adminAccount);

//     dispatch({
//       type: RETRIEVE_STORE_ADMINS,
//       payload: res.data,
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };
