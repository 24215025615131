import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '使用者功能曲線圖',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const initialLogData = {
  store: { data: ['', 0] },
  activity: { data: ['', 0] },
  product: { data: ['', 0] },
  pointcard: { data: ['', 0] },
  coupon: { data: ['', 0] },
  member: { data: ['', 0] },
  cart: { data: ['', 0] },
  game: { data: ['', 0] },
};
const initialLabelData = ['21/Oct/2022', '24/Oct/2022', '25/Oct/2022', '26/Oct/2022', '27/Oct/2022', '28/Oct/2022'];
const initialHashTable = {
  1234567: '',
};
const hashfn = labels => {
  let hashTable = {};
  labels.map(label => {
    hashTable[new Date(label).setHours(0, 0, 0, 0)] = label;
  });
  return hashTable;
};

const initialFilterDate = {
  start: `${new Date().getFullYear()}-${(new Date().getMonth() - 2).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
  end: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
};
export default function Table() {
  const classes = useStyles();
  const [labels, setLebels] = React.useState(initialLabelData);
  const [logdata, setLogData] = React.useState(initialLogData);
  const [filteredLabel, setFilteredLabel] = React.useState(initialLabelData);
  const [filteredLogdata, setFilteredLogData] = React.useState(initialLogData);
  const [hashTable, setHashTable] = React.useState(initialHashTable);
  React.useEffect(() => {
    getAnalysisLog();
  }, []);

  const getAnalysisLog = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.log.listFrontend(storeCode, token).then(response => {
      setLebels(response.data.logData.labels);
      setFilteredLabel(response.data.logData.labels);
      const rawData = JSON.parse(JSON.stringify(response.data.logData));
      rawData.store.data = Object.entries(rawData.store.data);
      rawData.activity.data = Object.entries(rawData.activity.data);
      rawData.product.data = Object.entries(rawData.product.data);
      rawData.pointcard.data = Object.entries(rawData.pointcard.data);
      rawData.coupon.data = Object.entries(rawData.coupon.data);
      rawData.member.data = Object.entries(rawData.member.data);
      rawData.cart.data = Object.entries(rawData.cart.data);
      rawData.game.data = Object.entries(rawData.game.data);
      delete rawData.labels;
      setLogData(rawData);
      setFilteredLogData(rawData);
      const labelHashTable = hashfn(response.data.logData.labels);
      setHashTable(labelHashTable);
    });
  };

  const data = {
    labels: filteredLabel,
    datasets: [
      {
        label: '商家資訊',
        data: filteredLogdata.store.data,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: '首頁活動資訊',
        data: filteredLogdata.activity.data,
        borderColor: 'rgba(255, 159, 64)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
      {
        label: '商品資訊',
        data: filteredLogdata.product.data,
        borderColor: 'rgba(255, 205, 86)',
        backgroundColor: 'rgba(255, 205, 86, 0.2)',
      },
      {
        label: '集點卡資訊',
        data: filteredLogdata.pointcard.data,
        borderColor: 'rgba(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: '優惠資訊',
        data: filteredLogdata.coupon.data,
        borderColor: 'rgba(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: '會員資訊',
        data: filteredLogdata.member.data,
        borderColor: 'rgba(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
      },
      {
        label: '購物車資訊',
        data: filteredLogdata.cart.data,
        borderColor: 'rgba(201, 203, 207)',
        backgroundColor: 'rgba(201, 203, 207, 0.2)',
      },
      {
        label: '遊戲資訊',
        data: filteredLogdata.game.data,
        borderColor: 'rgb(128, 155, 0)',
        backgroundColor: 'rgb(128, 155, 0, 0.2)',
      },
    ],
  };
  // 日期輸入
  const [filterDate, setFilterDate] = React.useState(initialFilterDate);
  const handleStartChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, start: event.target.value }));
  };
  const handleEndChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, end: event.target.value }));
  };

  // 過濾搜尋
  const handleClick = event => {
    event.preventDefault();
    setFilteredLabel(labels);
    setFilteredLogData(logdata);
    const startTime = new Date(filterDate.start).setHours(0, 0, 0, 0);
    const endTime = new Date(filterDate.end).setHours(0, 0, 0, 0);
    labelFilter(startTime, endTime);
    dataFilter(startTime, endTime);
  };
  const labelFilter = (startTime, endTime) => {
    // x 時間軸filter
    let filteredDateLabel = labels
      .map(label => new Date(label).setHours(0, 0, 0, 0))
      .filter(item => item >= startTime && item <= endTime);
    filteredDateLabel = filteredDateLabel.map(item => hashTable[item]);
    setFilteredLabel(filteredDateLabel);
  };
  const dataFilter = (startTime, endTime) => {
    // data filter 暴力過濾法 (待優化)
    const filteredData = Object.entries(logdata).map(item => {
      // 此層結構 => ['store',{...}]
      const result = Object.entries(item[1]).map(dataItem => {
        // 此層結構 => [['21/Oct/2022', 8],['22/Oct/2022', 6]]
        dataItem[1] = dataItem[1].filter(item => {
          // 此層結構 => ['21/Oct/2022', 8]
          const timestamp = new Date(item[0]).setHours(0, 0, 0, 0);
          return timestamp >= startTime && timestamp <= endTime;
        });
        return dataItem;
      });
      item[1] = Object.fromEntries(result);
      return item;
    });
    setFilteredLogData(Object.fromEntries(filteredData));
  };

  // 清除
  const handleClickReset = event => {
    event.preventDefault();
    setFilteredLabel(labels);
    setFilteredLogData(logdata);
    setFilterDate(initialFilterDate);
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.chart.frontend" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section} style={{ height: '80%' }}>
        <h3>
          開始時間: {filterDate.start} 結束時間: {filterDate.end}
        </h3>
        <input type="date" id="start" name="start" onChange={handleStartChange} value={filterDate.start} />
        <input type="date" id="end" name="end" onChange={handleEndChange} value={filterDate.end} />

        <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClick}>
          過濾{' '}
        </Button>
        <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClickReset}>
          清除{' '}
        </Button>
        <Line data={data} width={600} height={400} options={options} />
      </Box>
    </PageContainer>
  );
}
