/**
 * 使用Formik 進行表單處理和驗證
 */
import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { DateTimePicker } from '@material-ui/pickers';
import { CrossButton } from '../../../Components/buttonStyle';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditFormDialog(props) {
  const { open, setIsDialogOpen, children, rowData, initialValues, getCouponList, ...others } = props;
  const [data, setData] = React.useState(initialValues); //呈現表格用
  const [selectedImage, setSelectedImage] = React.useState({
    cpnImg: {},
    cpnBigImg: [],
    tag: null,
  }); //新照片儲存file用
  const [imageUrl, setImageUrl] = React.useState({ cpnImg: '', cpnBigImg: [] }); //預覽圖片畫面呈現用
  const [imgName, setImgName] = React.useState({ cpnImg: '', cpnBigImg: [] }); //更新資料庫用

  useEffect(() => {
    //將傳進來的rowData分別存進 data, imageUrl, imgName
    if (rowData.id !== undefined) {
      //1.存進data 呈現表單資料
      setData({
        ...rowData,
      });
      //2.存進imageUrl, 呈現預覽畫面使用
      setImageUrl({
        ...imageUrl,
        cpnImg: rowData.cpnImage,
        cpnBigImg: rowData.cpnBigImage,
      });
      //3.存進imgName，分析DB回傳圖片url,抽出檔名。 更新回存DB使用
      let cpnImageName_DB = '';
      let cpnBigImgName_DB = [];
      if (rowData.cpnImage) {
        cpnImageName_DB = new URL(rowData.cpnImage).pathname.split('/').slice(-1)[0]; //分析DB回傳URL->取得檔名->存字串
      }
      if (rowData.cpnBigImage.length > 0) {
        cpnBigImgName_DB = rowData.cpnBigImage.map(bigImgURL => {
          //分析URL->取得檔名
          return new URL(bigImgURL).pathname.split('/').slice(-1)[0];
        });
      }
      setImgName({
        ...imgName,
        cpnImg: cpnImageName_DB,
        cpnBigImg: cpnBigImgName_DB,
      });
      // console.log('step 1 / rowData / ', rowData);
      // console.log('step 1 / rowData / set / cpnimg URL', rowData.cpnImage);
      // console.log('step 1 / rowData / set / cpnBigimg URL', rowData.cpnBigImage);
      // console.log('step 1 / rowData / set /cpnImageName', cpnImageName_DB);
      // console.log('step 1 / rowData / set /cpnBigImgUrlName', cpnBigImgName_DB);
    }
  }, [rowData]);
  useEffect(() => {
    // console.log('selected image', selectedImage);
    if (selectedImage.tag == 0) {
      //tage = 0 代表小圖
      let imgURL = '';
      // console.log(
      //   'step 2/ selectImage change / tag 0 / small image /selectedImage.cpnImg is file?',
      //   selectedImage.cpnImg instanceof File,
      // );
      if (selectedImage.cpnImg instanceof File) {
        imgURL = URL.createObjectURL(selectedImage.cpnImg);
        // console.log('step 2/ selectImage change / tag 0 / 產生小圖URL', imgURL);
      } else {
        imgURL = '';
        // console.log('step 2/ selectImage change / 刪除小圖 / 小圖URL / 空', imgURL);
      }
      setImageUrl({
        ...imageUrl,
        cpnImg: imgURL,
      });
    } else if (selectedImage.tag == 1) {
      //tage = 1 代表大圖
      //取得selected image url 存進 imgurl
      // console.log('step 2 / add / before / selectedImage', selectedImage);
      // console.log('step 2 / add / before / imageUrl', imageUrl);
      // console.log('step 2 / add / before / imgName', imgName);
      let originImgURL = imageUrl.cpnBigImg;
      let ImgURL = [];
      if (originImgURL.length === 0) {
        // console.log('step 3 / img big url / length', imageUrl.cpnBigImg.length);
        // console.log('step 3 / imgUrl length = 0 / generating url / selectedImage', selectedImage);
        // console.log('step 3 / imgUrl length = 0 / generating url / before / img Url', imageUrl.cpnBigImg);
        ImgURL.push(URL.createObjectURL(selectedImage.cpnBigImg[0]));
      } else if (originImgURL.length < 4) {
        // console.log('step 3 / img big url / length', imageUrl.cpnBigImg.length);
        // console.log('step 3 / imgUrl length < 4 / generating url / selectedImage', selectedImage);
        // console.log('step 3 / imgUrl length < 4 / generating url / before / img Url', imageUrl.cpnBigImg);
        ImgURL.push(URL.createObjectURL(selectedImage.cpnBigImg.slice(-1)[0]));
      } else {
        alert('最多三張圖');
      }
      originImgURL = originImgURL.concat(ImgURL);
      // console.log('step 4 / img big url / length', originImgURL.length);
      // console.log('step 4 / generating url / after / img Url', originImgURL);
      setImageUrl({
        ...imageUrl,
        cpnBigImg: originImgURL,
      });
    }
    if (selectedImage.tag !== null) {
      return () => {
        setSelectedImage(prev => {
          let newState = Object.assign({}, prev); //shallow copy state
          newState.tag = null;
          return newState;
        });
      };
    }
  }, [selectedImage]);
  const resetState = () => {
    setSelectedImage(prev => {
      let newState = Object.assign({}, prev); //shallow copy state
      newState.cpnImg = {};
      newState.cpnBigImg = [];
      newState.tag = null;
      // console.log('handle close:', newState);
      return newState;
    });
    setImageUrl(prev => {
      let newState = Object.assign({}, prev); //shallow copy of object
      newState.cpnImg = '';
      newState.cpnBigImg = [];
      return newState;
    });
  };
  const handleClose = () => {
    setIsDialogOpen(false);
    resetState();
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    // console.log('[Edit Form Dialog / handle submit]');
    setIsDialogOpen(false);
    let cpnImgName = imgName.cpnImg;
    let cpnBigImgName = imgName.cpnBigImg;
    // console.log('submit selected state', selectedImage);
    // console.log('submit imgName state', imgName);
    if (selectedImage.cpnImg instanceof File) {
      // console.log('selected image updload');
      await ApiService.Image.upload(storeCode, 'coupon', selectedImage.cpnImg).then(res => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/store/${storeCode}/upload/coupon`,
            requestMethod: 'POST',
            responseStatus: res.status,
          },
          token,
        );
        cpnImgName = res.data.uuidName;
        // console.log('產生小圖uuid', cpnImgName);
      });
    }
    if (selectedImage.cpnBigImg.length > 0) {
      for (let item of selectedImage.cpnBigImg) {
        await ApiService.Image.upload(storeCode, 'coupon', item).then(res => {
          ApiService.log.add(
            storeCode,
            {
              requestUrl: `/store/${storeCode}/upload/coupon`,
              requestMethod: 'POST',
              responseStatus: res.status,
            },
            token,
          );
          cpnBigImgName.push(res.data.uuidName);
        });
      }
    } else {
      cpnBigImgName = cpnBigImgName;
    }
    setImgName({
      ...imgName,
      cpnImg: cpnImgName,
      cpnBigImg: cpnBigImgName,
    });
    const couponData = {
      cpnCode: values.cpnCode,
      cpnName: values.cpnName,
      cpnIntro: values.cpnIntro,
      cpnDesc: values.cpnDesc,
      cpnImage: cpnImgName,
      cpnBigImage: cpnBigImgName,
      cpnStartTime: values.cpnStartTime,
      cpnStopTime: values.cpnStopTime,
      cpnStatus: values.cpnStatus,
      cpnTarget: values.cpnTarget,
      cpnQty: values.cpnQty,
      cpnQtyGiven: values.cpnQtyGiven,
      cpnGiveRepeat: values.cpnGiveRepeat,
    };
    // console.log('coupon data', couponData);
    ApiService.Coupon.modify(storeCode, couponData, token).then(async response => {
      ApiService.log.add(
        storeCode,
        {
          requestUrl: `/coupon/${storeCode}`,
          requestMethod: 'PUT',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        await getCouponList();
      }
    });
    resetState();
  };
  const removeImage = (tag, name) => {
    // change selectedImage state
    // console.log('step 2 / remove / before / selectedImage', selectedImage);
    // console.log('step 2 / remove / before / imageUrl', imageUrl);
    // console.log('step 2 / remove / before / imgName', imgName);
    if (tag == 0) {
      //判斷小圖
      //1.清空selectedImage
      setSelectedImage({
        ...selectedImage,
        cpnImg: '',
        tag: 0,
      });
      setImgName({
        ...imgName,
        cpnImg: '',
      });
    } else if (tag == 1) {
      //判斷大圖
      // console.log(`Delete the big image ${name}`);
      // console.log('判斷大圖', imgName.cpnBigImg);
      let index = imgName.cpnBigImg.findIndex(item => {
        return item == name;
      });
      // console.log('index', index);
      let cpn_big_image_splice = imageUrl.cpnBigImg;
      cpn_big_image_splice.splice(index, 1);
      let filtered_name = imgName.cpnBigImg.filter((item, index) => {
        // console.log(`index ${index} name => ${item}`);
        return item !== name;
      });

      setImgName({
        ...imgName,
        cpnBigImg: filtered_name,
      });
      setImageUrl({
        ...imageUrl,
        cpnBigImg: cpn_big_image_splice,
      });

      // console.log('img name filtered', filtered_name);
      // console.log('img url after', cpn_big_image_splice);
    }
  };
  const validationSchema = yup.object().shape({
    cpnCode: yup.string('coupon Code').required('必填欄位'),
    cpnName: yup.string('coupon Name').required('必填欄位'),
  });
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>修改優惠券</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>修改下列資訊</DialogContentText>
          <Formik initialValues={data} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              console.log('data', data);
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs="6">
                      <Field
                        required
                        as={TextField}
                        autoFocus
                        margin="dense"
                        id="cpnCode"
                        name="cpnCode"
                        label="優惠券代碼"
                        type="text"
                        fullWidth
                        disabled
                        value={values.cpnCode}
                        onChange={handleChange}
                        error={touched.cpnCode && Boolean(errors.cpnCode)}
                        helperText={touched.cpnCode && errors.cpnCode}
                      />
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="cpnName"
                        name="cpnName"
                        label="優惠券名稱"
                        type="text"
                        fullWidth
                        disabled={rowData.expired}
                        value={values.cpnName}
                        onChange={handleChange}
                        error={touched.cpnName && Boolean(errors.cpnName)}
                        helperText={touched.cpnName && errors.cpnName}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="cpnIntro"
                        name="cpnIntro"
                        label="優惠券介紹"
                        type="text"
                        fullWidth
                        disabled={rowData.expired}
                        multiline
                        value={values.cpnIntro}
                        onChange={handleChange}
                        error={touched.cpnIntro && Boolean(errors.cpnIntro)}
                        helperText={touched.cpnIntro && errors.cpnIntro}
                      />
                      <Field
                        as={TextField}
                        defaultValue="coupon Description defaut content."
                        margin="dense"
                        id="cpnDesc"
                        name="cpnDesc"
                        label="優惠券說明"
                        type="text"
                        fullWidth
                        multiline
                        disabled={rowData.expired}
                        value={values.cpnDesc}
                        onChange={handleChange}
                        error={touched.cpnDesc && Boolean(errors.cpnDesc)}
                        helperText={touched.cpnDesc && errors.cpnDesc}
                      />
                      <Field
                        as={FormControl}
                        margin="dense"
                        id="cpnImage"
                        name="cpnImage"
                        label="優惠券小圖"
                        value={values.cpnImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-single-image"
                          style={{ display: 'none' }}
                          disabled={rowData.expired}
                          onChange={e =>
                            setSelectedImage({
                              ...selectedImage,
                              cpnImg: e.target.files[0],
                              tag: 0,
                            })
                          }
                        />
                        <label htmlFor="select-single-image">
                          <Button variant="contained" color="primary" component="span" disabled={rowData.expired}>
                            優惠券小圖
                          </Button>
                          <p>(圖片格式: 429px * 429px, 1張)</p>
                        </label>
                      </Field>
                      {imageUrl.cpnImg && selectedImage.cpnImg ? (
                        <Box mt={2} textAlign="center">
                          <div
                            style={{
                              position: 'relative',
                              width: '150px',
                              margin: 'auto',
                            }}>
                            <div>小圖預覽:</div>
                            <img src={imageUrl.cpnImg} alt={selectedImage.cpnImg.name} height="150px" />
                            <CrossButton
                              type="button"
                              disabled={rowData.expired}
                              onClick={() => removeImage(0, imgName.cpnImg)}>
                              &times;
                            </CrossButton>
                          </div>
                        </Box>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs="6">
                      <Field
                        as={FormControlLabel}
                        margin="dense"
                        control={<Checkbox id="cpnStatus" name="cpnStatus" />}
                        label="啟用狀態"
                        disabled={rowData.expired}
                        checked={values.cpnStatus}
                        onChange={handleChange}
                      />
                      <DateTimePicker
                        margin="dense"
                        id="cpnStartTime"
                        name="cpnStartTime"
                        label="優惠券開始時間"
                        value={values.cpnStartTime}
                        disabled={rowData.expired}
                        onChange={val => {
                          // console.log('picked coupon start time', val.format());
                          setFieldValue('cpnStartTime', val);
                        }}
                        renderInput={params => <TextField {...params} />}
                      />
                      <DateTimePicker
                        margin="dense"
                        id="cpnStopTime"
                        name="cpnStopTime"
                        label="優惠券結束時間"
                        value={values.cpnStopTime}
                        disabled={rowData.expired}
                        onChange={val => {
                          // console.log('picked coupon stop time', val.format());
                          setFieldValue('cpnStopTime', val);
                        }}
                        renderInput={params => <TextField {...params} />}
                      />
                      <Field required as={FormControl} margin="dense" fullWidth>
                        <InputLabel id="coupone-target">優惠對象</InputLabel>
                        <Select
                          labelId="coupone-target"
                          id="cpnTarget"
                          name="cpnTarget"
                          value={values.cpnTarget}
                          label="優惠對象"
                          disabled
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          onChange={e => {
                            setFieldValue('cpnTarget', e.target.value);
                          }}>
                          <MenuItem value="STORE">通用 (所有人都顯示/不限數量/無使用按鈕)</MenuItem>
                          <MenuItem value="PERSON">個人 (舊+新會員/每人1張/有使用按鈕)</MenuItem>
                          <MenuItem value="PERSON_NEW_USER">新會員 (只新會員/每人1張/有使用按鈕)</MenuItem>
                          <MenuItem value="PERSON_POINT_CARD">集點卡 (集點滿領取/集點卡可設重複集/有使用按鈕)</MenuItem>
                          <MenuItem value="PERSON_GROUP_GIVEN">群組用 (優惠群組連結領/可設重複領/有使用按鈕)</MenuItem>
                        </Select>
                      </Field>
                      {values.cpnTarget == 'PERSON_GROUP_GIVEN' ? (
                        <Box>
                          <Grid container spacing={1}>
                            <Grid item xs="6">
                              <Field
                                required
                                as={TextField}
                                margin="dense"
                                id="cpnQty"
                                name="cpnQty"
                                label="優惠券數量"
                                defaultValue="0"
                                value={values.cpnQty}
                                onChange={handleChange}
                                error={touched.cpnQty && Boolean(errors.cpnQty)}
                                helperText={touched.cpnQty && errors.cpnQty}
                              />
                            </Grid>
                            <Grid item xs="6">
                              <Field
                                required
                                as={TextField}
                                margin="dense"
                                id="cpnQtyGiven"
                                name="cpnQtyGiven"
                                label="優惠已送出數量"
                                defaultValue="0"
                                disabled
                                value={values.cpnQtyGiven}
                                onChange={handleChange}
                                error={touched.cpnQtyGiven && Boolean(errors.cpnQtyGiven)}
                                helperText={touched.cpnQtyGiven && errors.cpnQtyGiven}
                              />
                            </Grid>
                          </Grid>
                          <Field
                            fullWidth
                            as={FormControlLabel}
                            margin="dense"
                            control={<Checkbox id="cpnGiveRepeat" name="cpnGiveRepeat" />}
                            label="同一人可重複領取多張"
                            disabled={rowData.expired}
                            checked={values.cpnGiveRepeat}
                            onChange={handleChange}
                          />
                        </Box>
                      ) : null}
                      <Field
                        fullWidth
                        as={FormControl}
                        margin="dense"
                        id="cpnBigImage"
                        name="cpnBigImage"
                        label="優惠券大圖"
                        value={values.cpnBigImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-multi-image"
                          style={{ display: 'none' }}
                          disabled={rowData.expired}
                          onChange={e => {
                            if (e.target.files[0] !== undefined && imageUrl.cpnBigImg.length < 3) {
                              setSelectedImage(prev => {
                                // console.log('大圖', e.target.files[0]);
                                let newState = Object.assign({}, prev); //shallow copy state
                                newState.cpnBigImg.push(e.target.files[0]);
                                newState.tag = 1;
                                return newState;
                              });
                            }
                          }}
                        />
                        <label htmlFor="select-multi-image">
                          <Button variant="contained" color="primary" component="span" disabled={rowData.expired}>
                            優惠券大圖
                          </Button>
                          <p>(圖片格式: 960px * 480px, 最多3張圖)</p>
                        </label>
                      </Field>
                      {imageUrl.cpnBigImg.length > 0 ? (
                        <Grid container spacing={2}>
                          {imageUrl.cpnBigImg.map((imgUrl, index) => {
                            return (
                              <Grid key={index}>
                                <Box mt={2} textAlign="center" style={{ position: 'relative' }}>
                                  <div>大圖預覽{index + 1}:</div>
                                  <img src={imgUrl} alt={`Image ${index + 1}`} height="100px" />
                                  <CrossButton
                                    type="button"
                                    class="close"
                                    disabled={rowData.expired}
                                    onClick={() => removeImage(1, imgName.cpnBigImg[index])}>
                                    &times;
                                  </CrossButton>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    {rowData.expired ? (
                      <Button type="submit" color="primary" disabled={true}>
                        確定修改
                      </Button>
                    ) : (
                      <Button type="submit" color="primary" disabled={isSubmitting}>
                        確定修改
                      </Button>
                    )}
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
