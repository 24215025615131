import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import localStorageService from 'services/LocalStorageService';
import ApiService from 'services/apiService';
import DataTable from './DataTable';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export default function Table() {
  const classes = useStyles();
  const [couponUsage, setCouponUsage] = React.useState();
  React.useEffect(() => {
    getCouponUsageList();
  }, []);
  const getCouponUsageList = async () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Coupon.usageList(storeCode, token)
      .then(response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/coupon/usage/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          console.log('response', response.data);
          setCouponUsage(response.data.couponUsageList);
        } else {
          console.log('coupon usage', 'coupon usaage/list response:' + JSON.stringify(response.data));
        }
      })
      .catch(err => console.log('err', err));
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.coupon.usage.list" />}>
      <Box className={classes.section}>
        <DataTable couponUsage={couponUsage} />
      </Box>
    </PageContainer>
  );
}
