import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import FormDialog from './FormDialog';
import newNameCode from 'services/nameCodeGenerator';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

let currentDate = new Date();
const initialValues = {
  pcCode: '',
  pcTitle: '',
  pcSubTitle: '',
  pcDesc: '',
  pcStartTime: currentDate.toString(),
  pcStopTime: currentDate.setDate(currentDate.getDate() + 7),
  pcMaxPoint: 10,
  pcGotPoint: 1,
  pcRepeatable: true,
  couponId: 0,
};
export default function Table() {
  const classes = useStyles();
  const [pointcard, setPointcard] = React.useState();
  const [coupon, setCoupon] = React.useState();
  React.useEffect(() => {
    getPointcardList();
    getCouponListForPerson();
  }, []);
  const getPointcardList = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.Pointcard.list(storeCode)
      .then(response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/pointcard/list/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          // console.log('response', response.data);
          setPointcard(response.data.pointcardList);
          newName('PTC', response.data.pointcardList);
        } else {
          console.log('Point Card', 'Point Card/list response:' + JSON.stringify(response.data));
        }
      })
      .catch(err => console.log('err', err));
  };
  const getCouponListForPerson = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.Coupon.list(storeCode)
      .then(response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/coupon/list/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          // console.log('response', response.data);
          let couponPerson = response.data.couponList.filter((item, index, array) => {
            return item.cpnTarget == 'PERSON_POINT_CARD';
          });
          // console.log(couponPerson)
          setCoupon(couponPerson);
        } else {
          console.log('coupon', 'coupon/list response:' + JSON.stringify(response.data));
        }
      })
      .catch(err => console.log('err', err));
  };

  const newName = (prefix, dataList) => {
    if (dataList != undefined) {
      let nameCodeDB = dataList.map(dataInstance => {
        return dataInstance.pcCode;
      });
      initialValues.pcCode = newNameCode(prefix, nameCodeDB);
    }
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.pointcard.list" />}>
      <Box className={classes.section}>
        <DataTable coupon={coupon} pointcard={pointcard} getPointcardList={getPointcardList} />
      </Box>
      <Box className={classes.section}>
        <FormDialog coupon={coupon} initialValues={initialValues} getPointcardList={getPointcardList} />
      </Box>
    </PageContainer>
  );
}
