import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
//import { Helmet } from "react-helmet-async";

export const store = configureStore();

const App = () => (
  <div>
    {/*<Helmet>
      <meta
        httpEquiv="Content-Security-Policy"
        content="default-src 'self' 'unsafe-inline' *.googleapis.com localhost:8080 *.lciad.net *.agtoptv.com *.membercard.cc *.528shop.tv; script-src 'self'; script-src-elem 'self' 'unsafe-inline' *.googleapis.com localhost:8080 *.lciad.net *.agtoptv.com *.membercard.cc *.528shop.tv; style-src 'self' 'unsafe-inline';"
      ></meta>
    </Helmet>*/}
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppContextProvider>
          <AppWrapper>
            <Switch>
              <Routes />
            </Switch>
          </AppWrapper>
        </AppContextProvider>
      </ConnectedRouter>
    </Provider>
  </div>
);

export default App;
